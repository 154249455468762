import { request } from "../utils/request"

//考试5道题一保存
export function examRecord(data) {
  return request({
        url: 'stuwebapi/study.exam/examRecord',
        method: 'post',
        data
    })
}

//考试交卷
export function examSubmit(data) {
  return request({
        url: 'stuwebapi/study.exam/examSubmit',
        method: 'post',
        data
    })
}

//获取模拟考试列表
export function getMoExamList(data) {
    return request({
        url: 'stuwebapi/study.practice/getPracticeList',
        method: 'post',
        data,
        isLoading: data.load,
        isShowMsg: data.isShowMsg,
        isShowErr: data.isShowErr
    })
}

//获取模拟考试试题
export function getMoExamQues(data) {
    return request({
        url: 'stuwebapi/study.practice/getPracticeQues',
        method: 'post',
        data,
        isLoading: data.load,
        isShowMsg: data.isShowMsg,
        isShowErr: data.isShowErr
    })
}


//个人专考试题获取
export function getZhuanKaoExamQues(data) {
  return request({
        url: 'stuwebapi/study.personal_exam/ques',
        method: 'post',
        data,
        isLoading: data.load,
        isShowMsg:data.isShowMsg,
        isShowErr:data.isShowErr
    })
}


//收藏 模拟考试
export function moExamCollect(data) {
  return request({
    url: 'stuwebapi/study.practice/practiceCollect',
    method: 'post',
    data
  });
}

