import { request } from "../utils/request"

export function _getDateList(data) {
    return request({
        url: 'stuwebapi/study.report/getExamDays',
        method: 'post',
        data,
        isLoading: data.load ? data.load : false,
        isShowMsg: data.isShowMsg ? data.isShowMsg : false,
        isShowErr: data.isShowErr ? data.isShowErr : false,
        isHideToken: data.isHideToken ? data.isHideToken : false,
        
    })
}
export function _getDateListStudy(data) {
    return request({
        url: 'stuwebapi/study.report/getStudyDays',
        method: 'post',
        data,
        isLoading: data.load ? data.load : false,
        isShowMsg: data.isShowMsg ? data.isShowMsg : false,
        isShowErr: data.isShowErr ? data.isShowErr : false,
        isHideToken: data.isHideToken ? data.isHideToken : false,
    })
}
export function _getSubjectList(data) {
    return request({
        url: 'stuwebapi/study.report/getSubList',
        method: 'post',
        data,
        isLoading: data.load ? data.load : false,
        isShowMsg: data.isShowMsg ? data.isShowMsg : false,
        isShowErr: data.isShowErr ? data.isShowErr : false,
        isHideToken: data.isHideToken ? data.isHideToken : false,
    })
}

export function _getSubExamList(data) {
    return request({
        url: 'stuwebapi/study.report/getExamList',
        method: 'post',
        data,
        isLoading: data.load ? data.load : false,
        isShowMsg: data.isShowMsg ? data.isShowMsg : false,
        isShowErr: data.isShowErr ? data.isShowErr : false,
        isHideToken: data.isHideToken ? data.isHideToken : false,

        
    })
}
export function _getOneExam(data) {
    return request({
        url: 'stuwebapi/study.report/examScreen',
        method: 'post',
        data,
        isLoading: data.load ? data.load : false,
        isShowMsg: data.isShowMsg ? data.isShowMsg : false,
        isShowErr: data.isShowErr ? data.isShowErr : false,
        isHideToken: data.isHideToken ? data.isHideToken : false,
    })
}
export function _getAllExam(data) {
    return request({
        url: 'stuwebapi/study.report/grantScreen',
        method: 'post',
        data,
        isLoading: data.load ? data.load : false,
        isShowMsg: data.isShowMsg ? data.isShowMsg : false,
        isShowErr: data.isShowErr ? data.isShowErr : false,
        isHideToken: data.isHideToken ? data.isHideToken : false,
    })
}

export function _reportSubject(data) {
    return request({
        url: 'stuwebapi/study.report/exportPaper',
        method: 'post',
        data,
        isLoading: data.load ? data.load : false,
        isShowMsg: data.isShowMsg ? data.isShowMsg : false,
        isShowErr: data.isShowErr ? data.isShowErr : false,
        isHideToken: data.isHideToken ? data.isHideToken : false,
    })
}

export function _getStudyInfo(data) {
    return request({
        url: 'stuwebapi/study.report/studyScreen',
        method: 'post',
        data,
        isLoading: data.load ? data.load : false,
        isShowMsg: data.isShowMsg ? data.isShowMsg : false,
        isShowErr: data.isShowErr ? data.isShowErr : false,
        isHideToken: data.isHideToken ? data.isHideToken : false,
    })
}
