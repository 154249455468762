<template>
  <div class="app_box" @click.stop.prevent="closeSetBox()">
    <div class="top" v-if="showNavbar">
      <div class="top_box">
        <img src="../img/logo.png" class="logo_pic" alt="" />
        <div class="nav_box">
          <div class="nav_item" :class="[index == pageIndex ? 'nav_item_select' : '']" v-for="(item, index) in navArr"
            @click="changeNav(index)">
            {{ item }}

            <div class="nav_line" :class="[
              index == pageIndex ? 'nav_line_select' : 'nav_line_unselect',
            ]"></div>
          </div>
        </div>
        <div class="head_box">
          <img class="head_img" src="../img/index_head.png" alt="" @click.stop.prevent="openSetBox()">
          <div class="set_box" :class="[isSetBoxOpen == false ? 'set_box_close' : '']">
            <div class="up"></div>
            <div class="set_list">
              <div class="set_item">
                <img src="../img/index_change.png" alt="">
                <div class="text">切换机构</div>
              </div>
              <div class="set_item">
                <img src="../img/index_set.png" alt="">
                <div class="text">账号密码管理</div>
              </div>
              <div class="set_item" @click="exit()">
                <img src="../img/index_exit.png" alt="">
                <div class="text">退出</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="main_box" :class="[showNavbar == false||isHaveTop==false ? '' : 'main_box_top']"> -->


    <div class="main_box" :class="[showNavbar == false ? 'main_box_auto' : 'main_box_top']">

      <router-view />
    </div>
  </div>
</template>

<script>
// import md5 from "js-md5";

export default {
  components: {},
  created() {
    this.checkRoute();
  },
  mounted() { },
  data() {
    return {
      isSetBoxOpen: false,
      showNavbar: true, // 默认显示导航栏
      isHaveTop:true,
      pageIndex: 0,
      navArr: ["班级名称"],
      // navArr: ["首页", "班级名称"],
    };
  },
  watch: {
    // 监听路由变化，动态控制导航栏的显示
    $route: function (to, from) {
      this.checkRoute();
    },
  },
  methods: {

    exit() {
      // window.localStorage.removeItem("userToken")
      window.localStorage.clear()
      this.$router.replace({ path: "/" });
    },
    openSetBox() {
      this.isSetBoxOpen = this.isSetBoxOpen == true ? false : true
    },
    closeSetBox(){
      this.isSetBoxOpen = false
    },
    checkRoute() {
      
      const noNavPages = [
        "/Index/TYKSPage",
        "/Index/DKLXPage",
        "/Index/BigScreen",
        "/Index/BigScreen/BigScreenExam",
        "/Index/BigScreen/BigScreenExamJump",
        "/Index/BigScreen/BigScreenStudy",
        "/Index/LookAll",
        "/Index/LookWrong",
      ]; // 需要隐藏导航栏的页面路径数组
      if (noNavPages.includes(this.$route.path)) {
        this.showNavbar = false;
      } else {
        this.showNavbar = true;
      }
      const noNavPages2 = [
        "/Index/DKLXList",
      ]; // 需要隐藏导航栏的页面路径数组
      if (noNavPages2.includes(this.$route.path)) {
        this.isHaveTop = true;
      } else {
        this.isHaveTop = null;
      }
    },
    changeNav(index) {
      this.pageIndex = parseInt(index);
      switch (this.pageIndex) {
        case 0:
          // 首页
          this.$router.replace({ path: "/Index/ClassName", replace: true });
          break;
          // case 1:
          //   // 班级名称
          //   this.$router.replace({ path: "/Index/ClassName" , replace:true});
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style scoped>
page {
  position: relative;
}

.app_box {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

}

.top {
  width: 100%;
  height: 60px;
  position: fixed;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  z-index: 20;
  background-color: #fff;
}

.top_box {
  width: 1200px;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin: 0 auto;
}

.main_box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: rgba(245, 247, 250, 1);
  position: fixed;
  left: 0;
  top: 0;
  
}
.main_box_auto {
  overflow-y: auto;

}
.main_box_top {
  padding-top: 60px;

}

.logo_pic {
  width: 100px;
  height: 38px;
}

.nav_box {
  flex: 1;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 115px;
}

.head_box {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #ccc;
  position: relative;
  cursor: pointer;
}

.head_box .head_img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.set_box {
  width: 167px;
  height: 120px;
  background-color: rgba(155, 178, 250, 1);
  position: absolute;
  right: 0;
  top: 50px;
  box-sizing: border-box;
  overflow: visible;
  transition: all 0.1s linear;

}

.set_box_close {
  height: 0;
  overflow: hidden;
}

.up {
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
  border-bottom: 12px solid rgba(155, 178, 250, 1);
  position: absolute;
  right: 5px;
  top: -19px;
  z-index: 0;
}

.nav_item {
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  margin-right: 45px;
  cursor: pointer;
  position: relative;
  z-index: 5;
}

.nav_item_select {
  background: linear-gradient(90deg,
      rgba(45, 155, 255, 1) 0%,
      rgba(100, 110, 255, 1) 100%);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.nav_line {
  width: 100%;
  height: 2px;
  border-radius: 10px;

  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
}

.nav_line_unselect {
  background: transparent;
}

.nav_line_select {
  background: linear-gradient(90deg,
      rgba(45, 155, 255, 1) 0%,
      rgba(100, 110, 255, 1) 100%);
}

.set_list {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.set_item {
  display: flex;

  width: 100%;
  box-sizing: border-box;
  padding: 8px 12px;
}

.set_item:hover {
  background-color: rgba(100, 110, 255, 1);
}

.set_item img {
  width: 24px;
  height: 24px;
}

.set_item .text {
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  margin-left: 12px;
}</style>
