<template>
    <div class="myChart8"
        style="width: 100%; height: 90%;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);">
        <div class="myChart8_title_box">
            <div>
                <!-- <div class="jump_btn" @click="jumpDeail">跳转详情</div> -->
            </div>
            <div class="chart_title">
                <div class="chart_title_item" v-for="(item, index) in titleArr" :key="index">
                    <div class="chart_yuan " :class="item.color"></div>
                    <div>{{ item.name }}</div>
                    <div v-if="pageInfo">（正确率 ：{{ pageInfo.accuracy[index] }}%)</div>
                </div>
            </div>
        </div>
        <div class="chart_subject_box" :class="[isOpenSubject == true ? 'chart_subject_box_open' : '']"
            @click="closeSubject">
            <div class="info">
                <span style="color: #FF7D00;margin-right: .1875rem;">难度：{{ subjectHard }}</span>
                <span v-if="subjectStatus == -1">答题状态： <span class="text_yellow">人工批阅</span></span>
                <span v-if="subjectStatus == -2">答题状态：<span class="text_yellow">未答</span></span>
                <span v-if="subjectStatus == 0">答题状态：<span class="text_red">错误</span> </span>
                <span v-if="subjectStatus == 1">答题状态：<span class="text_green">正确</span></span>
            </div>
            <div style="margin-top:0.15rem;border-bottom: 1px solid #ccc;padding-bottom: .025rem;"> <span
                    style="margin-right:0.15rem">{{ subjectIndex + 1 }}.</span>{{ subjectInfo }}</div>
            <div class="subject_list">
                <div class="subject_item" v-for="(item, index) in subjectList" :key="index">
                    <div style="margin-right:0.15rem;">{{ item.item_chr }} : </div>
                    <div v-html="item.item"></div>
                </div>
            </div>
            <div class="ques_box">
                <div class="item">
                    <div>题目答案：</div>
                    <div class="text_green">{{ rightSelect }}</div>
                </div>
                <div class="item">
                    <div>选择答案：</div>
                    <div class="text_red" v-if="subjectStatus == 0">{{ userSelect }}</div>
                    <div class="text_green" v-if="subjectStatus == 1">{{ userSelect }}</div>
                    <div class="text_yellow" v-if="subjectStatus == -1">人工批阅</div>
                    <div class="text_yellow" v-if="subjectStatus == -2">未答</div>
                </div>
            </div>
            
            <!-- this.subjectList = this.pageInfo.data[index][4][0]
            this.rightSelect = this.pageInfo.data[index][4][1]
            this.userSelect = this.pageInfo.data[index][4][2] -->
            <!-- <div class="line"></div> -->
            <div class="close_box">
                <img class="close" src="../../../img/close.png" alt="">
            </div>
        </div>
        <div class="chart_info_box" v-if="pageInfo">
            <div class="chart_info_list" @mouseleave="closeSubject">
                <div class="chart_info_item" v-for="(item, index) in pageInfo.data" @mouseenter="lookSubject(index)">
                    <div class="bg color_1" v-if="item[0] == 1"></div>
                    <div class="bg color_2" v-if="item[0] == 2"></div>
                    <div class="bg color_3" v-if="item[0] == 4"></div>
                    <div class="info_box">
                        <div class="text">{{ index + 1 }}</div>
                        <div class="sjx_r_w sjx_r" v-if="item[1] == 1"></div>
                        <div class="sjx_r_w sjx_w" v-if="item[1] == 0"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
   
<script>
var that = this
import * as echarts from 'echarts';


export default {
    name: 'MyChart8',
    props: {
        pageData: Object
    },
    watch: {

        immediate: true,
        deep: true,
        pageData: {
            handler: function (val, oldval) {
                
                if (val) {
                    this.pageInfo = val.pageInfo
                }

            },
            deep: true//对象内部的属性监听，也叫深度监听  
        }
    },
    data() {
        return {
            subjectIndex: null,
            subjectList: [],
            userSelect: "",
            userRight: null,
            rightSelect: "",
            subjectInfo: "",
            subjectHard: "",
            subjectStatus: "",
            isOpenSubject: false,
            pageInfo: null,
            is1920: false,
            titleArr: [
                {
                    name: "单选题",
                    isShow: true,
                    color: "color_1"
                },
                {
                    name: "多选题",
                    isShow: true,
                    color: "color_2"
                },
                {
                    name: "判断题",
                    isShow: true,
                    color: "color_3"
                },
            ]
        }
    },
    mounted() {
        if (parseInt(window.screen.width) >= 1920) {
            this.is1920 = true

        } else {
            this.is1920 = false
        }
    },
    methods: {
        jumpDeail() {
            // this.$router.replace({ name: "BigScreenExam" });
        },
        lookSubject(index) {

            console.log(this.pageInfo.data[index][4][1]);
            console.log(this.pageInfo.data[index][4][2]);

            this.subjectIndex = index
            this.isOpenSubject = true

            this.subjectList = this.pageInfo.data[index][4][0]
            this.rightSelect = this.pageInfo.data[index][4][1]
            this.userSelect = this.pageInfo.data[index][4][2]


            this.subjectStatus = this.pageInfo.data[index][1]
            this.subjectHard = this.pageInfo.data[index][2]
            this.subjectInfo = this.pageInfo.data[index][3]



            // 
        },
        closeSubject() {
            this.isOpenSubject = false
            this.subjectInfo = ""
            this.subjectHard = ""
            this.subjectStatus = ""

        }
    }
};
</script>
<style scoped>
.myChart8 {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 .25rem;
    display: flex;
    flex-direction: column;

}

.myChart8_title_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.myChart8 .chart_title {
    display: flex;
    align-items: center;
    justify-content: flex-end;

}

.myChart8 .chart_title .chart_title_item {
    display: flex;
    align-items: center;
    margin-right: .15rem;
    justify-content: flex-end;
    color: #fff;
    cursor: pointer;
}

.myChart8 .chart_title_item .chart_yuan {
    width: .15rem;
    height: .15rem;
    border-radius: 50%;
    margin-right: .1rem;
}

.color_1 {
    background-color: #12ddfb;
}

.color_2 {
    background-color: #249eff;
}

.color_3 {
    background-color: #7380ff;
}


.chart_subject_box {
    width: 90%;
    height: 0;
    background-color: #06194b;
    box-sizing: border-box;
    margin: .125rem 0;
    transition: all 0.1s linear;
    color: #ccc;
    box-sizing: border-box;
    white-space: normal;
    /* 允许换行 */
    overflow-wrap: break-word;
    /* 在必要时单词内部断行 */
    position: absolute;
    left: 50%;
    top: -100%;
    transform: translate(-50%, 0);
    z-index: 19;
    overflow: hidden;
    border: none;
}

.chart_subject_box_open {
    height: auto !important;
    border: 1px solid #fff;
    top: -3.3rem;

    padding: .25rem !important;
}

.close_box {
    width: .2rem;
    height: .2rem;
    border: 1px solid #fff;
    position: absolute;
    border-radius: 50%;
    right: 0;
    top: .1rem;
    transform: translate(-50%, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.close {
    width: 90%;
    height: 90%;
}

.chart_subject_box .line {
    width: 4px;
    height: 38px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 1.02rem;

}

.chart_subject_box .info {
    display: flex;
}


.chart_info_box {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0 .125rem 0;
    overflow: auto;
    flex: 1;

}

.chart_info_list {
    width: 100%;
    flex-shrink: 0;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin-top: .25rem;
}

.chart_info_item {
    flex-shrink: 0;
    width: .6375rem;
    height: .6375rem;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.1s linear;
}

.chart_info_item:hover {

    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.75);
    z-index: 99;
}

.chart_info_item .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
}

.chart_info_item .info_box {
    position: relative;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chart_info_item .text {
    font-size: .15rem;
    font-weight: 300;
    color: #000;
}

.sjx_r_w {
    width: 0;
    height: 0;
    position: absolute;
    left: 0px;
    top: 0px;
}

.sjx_w {
    border-top: 8px solid red;
    border-right: 8px solid transparent;
}

.sjx_r {
    border-top: 8px solid #1d5e07;
    border-right: 8px solid transparent;
}

.text_green {
    color: #1d5e07;
}

.text_red {
    color: red;
}

.text_yellow {
    color: #ccc;
}

.jump_btn {
    padding: 6px 20px;
    border: 1px solid #1ec1e6 !important;
    color: #1ec1e6;
    font-size: .15rem;
    cursor: pointer;
}

.subject_list {
    width: 100%;

}

.subject_list .subject_item {
    width: 100%;
    display: flex;
    margin: .15rem 0;

}

.ques_box {
    display: flex;
    margin-top: 20px;
}

.ques_box .item {
    display: flex;
    font-weight: 700;
    margin-right: .25rem;
}</style>

