import * as exerciseApi from '@/api/exercise.js';
import * as global from './global.js';

const StudyType = global.StudyType;

const PatternType = global.PatternType;

const QuestType = global.QuestType;

export default {
  computed: {
    m_QuestType() {
      return QuestType;
    },

    m_StudyType() {
      return StudyType;
    },

    m_PatternType() {
      return PatternType;
    },

    m_process() {
      if (!this.m_subjectInfo.count_num) {
        return {
          current: 0,
          total: 0,
          percentage: 0
        }
      }
      return {
        current: this.m_subjectInfo.answer_num,
        total: this.m_subjectInfo.count_num,
        percentage: parseInt(this.m_subjectInfo.answer_num * 100 / this.m_subjectInfo.count_num)
      }
    },

  },
  data() {
    return {
      m_subjectInfo: {},
      m_subjectIndex: 0,
      m_subList: [],
      m_unitList: [],
      m_quesTypeList: [],
      m_analysis: {},
      m_pattern_type: 2,

      m_pro_sub_id:null,
    };
  },

  created() {

  },

  methods: {
    m_init() {
      this.m_pro_sub_id = this.$route.query.pro_sub_id;
      const loadingInstance = global.showLoading('初始化');
      //获取科目列表
      exerciseApi.subject({
        pro_id: localStorage.getItem('proID'),
      }).then(res => {
        //科目列表
        this.m_subList = res.data;
      }).then(res => {
        //获取科目相关数据
        return this._m_requestData();
      }).catch(e => {
        global.showMessage('获取数据失败');
      }).finally(() => {

        loadingInstance.close();
      });
    },

    m_showClearCuoTiModal() {
      this.clearConfirmModalData = {
        ...this.clearConfirmModalData,
        title: '确认清除错题',
        info: '确认要清除错题吗？',
        confirm: async () => {
          try {
            const res = await exerciseApi.studyWheel({
              study_type: StudyType.CuoTiZhuanKao,
              pro_sub_id: this.m_subList[this.m_subjectIndex].pro_sub_id,
              pro_id: localStorage.getItem('proID'),
              handle_type: 2, //1:重新答题, 2:清空数据
              pattern_type: this.m_pattern_type
            });
            global.showMessage('错题清除成功', 'success');
          } catch (e) {
            global.showMessage('错题清除失败');
          }

        },
      }
      this.isShowClearConfirmModal = true;
      this.isOpenMore = false;
    },

    m_showClearShouCangModal() {
      this.clearConfirmModalData = {
        ...this.clearConfirmModalData,
        title: '确认清除收藏',
        info: '确认清除收藏吗？',
        confirm: async () => {
          try {
            const res = await exerciseApi.studyWheel({
              study_type: StudyType.ShouCangZhuanKao,
              pro_sub_id: this.m_subList[this.m_subjectIndex].pro_sub_id,
              pro_id: localStorage.getItem('proID'),
              handle_type: 2, //1:重新答题, 2:清空数据
              pattern_type: this.m_pattern_type
            });
            global.showMessage('收藏清除成功', 'success');
          } catch (e) {
            global.showMessage('收藏清除失败');
          }
        },
      }
      this.isShowClearConfirmModal = true;
      this.isOpenMore = false;
    },

    m_subjectChange(pro_sub_id) {
      const loadingInstance = global.showLoading('初始化');
      //获取科目相关数据
      this._m_requestData(pro_sub_id).catch(e => {
        global.showMessage('获取数据失败');
      }).finally(() => {
        loadingInstance.close();
      });
    },

    m_requestUnits() {
      const loadingInstance = global.showLoading('获取章节');
      exerciseApi.unitList({
        pro_id: localStorage.getItem('proID'),
        pro_sub_id: this.m_subList[this.m_subjectIndex].pro_sub_id
      }).then(res => {
        this.isShadeShow3 = true
        this.m_unitList = res.data;
      }).catch(e => {
        global.showMessage('获取数据失败');
      }).finally(() => {
        loadingInstance.close();
      });
    },

    m_startZhangJieLianXi(data) {
      this._m_goToDKLXPage({
        study_type: StudyType.ZhangJie,
        unit_id: data.id
      });
    },

    m_startQuanTiKu(sericalInput) {
      sericalInput = parseInt(sericalInput);
      const data = {
        study_type: StudyType.QuanTiKu
      };
      let serical = null;
      if (sericalInput && sericalInput > 0) {
        serical = sericalInput;
      } else {
        serical = this.m_pattern_type == PatternType.BeiTi ? this.m_subjectInfo.look_serical : this
          .m_subjectInfo.reply_serical;
      }
      data.serical = serical;
      this._m_goToDKLXPage(data);
    },

    m_startTiXingZhuanKao(ques_type) {
      this._m_goToDKLXPage({
        study_type: StudyType.TiXingZhuanKao,
        ques_type
      });
    },

    m_startCuoTiZhuanKao() {
      this._m_goToDKLXPage({
        study_type: StudyType.CuoTiZhuanKao,
      });
    },

    m_startShouCangZhuanKao() {
      this._m_goToDKLXPage({
        study_type: StudyType.ShouCangZhuanKao,
      });
    },

    _m_goToDKLXPage(query) {
      this.$router.replace({
        name: "DKLXPage",
        query: {
          pattern_type: this.m_pattern_type,
          pro_sub_id: this.m_subList[this.m_subjectIndex].pro_sub_id,
          // unit_id: null,
          // ques_type: null,
          ...query
        }
      });
    },


    async _m_requestData(pro_sub_id = null) {
      //班级id
      const pro_id = localStorage.getItem('proID');

      //科目详情
      let subjectInfoRes = null;
      if (pro_sub_id == null) {
        //如果是练题set_id不为0返回到该页面的情况
        if (this.m_pro_sub_id != null) {
          subjectInfoRes = await exerciseApi.subjectInfo({
            pro_id,
            pro_sub_id:this.m_pro_sub_id
          });
        } else {
          subjectInfoRes = await exerciseApi.subjectInfo({
            pro_id,
          });
        }

      } else {
        subjectInfoRes = await exerciseApi.subjectInfo({
          pro_id,
          pro_sub_id
        });
      }

      this.m_subjectInfo = subjectInfoRes.data;
      if (this.ce_isCantExercise(this.m_subjectInfo.set_id)) {
        this.ce_showCantExercisePop(this.m_subjectInfo.set_id);
      } else {
        this.ce_isShowCantExerciseModal = false;
      }

      this.m_subjectIndex = this.m_subList.findIndex(item => item.pro_sub_id == this.m_subjectInfo.pro_sub_id);

      //存储乱序
      localStorage.setItem('is_ques_random', this.m_subjectInfo.is_ques_random);

      //题型练习列表
      const quesTypeListRes = await exerciseApi.quesTypeList({
        pro_id,
        pro_sub_id: this.m_subjectInfo.pro_sub_id,
      });
      this.m_quesTypeList = quesTypeListRes.data;

      //数据分析
      const dataAnalysisRes = await exerciseApi.dataAnalysis({
        pro_id,
        pro_sub_id: this.m_subjectInfo.pro_sub_id,
      });
      this.m_analysis = dataAnalysisRes.data;

    }

  }
}
