<template>
  <div v-cloak>
    <div class="TYKS_page" v-if="pageInfo.length!=0">
      <div class="exam_item" v-for="(item, index) in pageInfo" :key="index">
        <div class="img_box">
          <img src="https://resources.sxsaas.com/static/jigou-saas-app/examDefault.png" alt="" />
        </div>
        <div class="info_box">
          <div class="base_info">
            <div class="title">{{ item.title }}</div>
            <div class="detail">
              <div v-if="!isMoKao" class="">考试时间：{{ item.start_time }}</div>
              <div class="">考试时长：{{ item.exam_duration }}分钟</div>
              <div class="">合格分数：{{ item.qualified_score }}分</div>
              <div v-if="!isMoKao" class="">已考次数：{{ item.exam_count }}/{{item.allow_num}}次</div>
              <div v-else  class="">已考次数：{{ item.exam_count }}次</div>
            </div>
          </div>
          <div class="subject_info">
            <div class="subject_item">
              <div>总题数</div>
              <div>共{{ item.ques_count }}题</div>
              <div>{{  item.score }}分</div>
            </div>
            <div class="subject_item" v-for="(item2,index2) in item.ques_type" :key="index2">
              <div>{{item2.title}}</div>
              <div>共{{item2.ques_count}}题</div>
              <div>{{item2.score}}分</div>
            </div>
            <div v-if="isMoKao && ce_isCantExercise(item.open_set.set_id) && item.open_set.entry_time" class="subject_item">下次允许进入: {{item.open_set.entry_time}} </div>
          </div>
          <template>
            <div v-if="isMoKao && ce_isCantExercise(item.open_set.set_id)" class="jump_btn disabled">当前时间不允许模拟考试</div>
            <div v-else class="jump_btn" @click="jumpExam(index)" v-throttle>进入考试</div>
          </template>

        </div>
      </div>
    </div>
    <div v-else>
      <nullBox></nullBox>
    </div>
    <div class="page_end_fixed">
      <endBox></endBox>
    </div>

    <comfirm2  @clickComfirm="cantExerciseComfirm" :data="ce_cantExerciseComfirmData" v-if="ce_isShowCantExerciseModal"></comfirm2>
  </div>
</template>

<script>
import { _getExamList} from "../../api/TYKSList"
import * as examinationApi from '@/api/examination.js';
import * as exerciseApi from '@/api/exercise.js';
import * as global from '@/mixins/global.js';
import comfirm2 from "../../components/comfirm2.vue"
import cantExerciseMixin from '@/mixins/cant-exercise.js';

export default {
  mixins: [cantExerciseMixin],
  components: {
    comfirm2
  },
  created() {
    this.getExamList()
  },

  mounted() {},
  data() {
    return {
      pageInfo: [],
    };
  },
  computed:{
    isMoKao(){
      return this.$route.query.examType == global.ExamType.MoKao;
    },
  },
  methods: {
    async getExamList(){
      let params = {
        pro_id: window.localStorage.getItem("proID"),
        load: true,
        isShowErr: true,
      }

      let res;
      //模考
      if(this.isMoKao) {
        res= await examinationApi.getMoExamList(params)
      } else {
        res= await _getExamList(params)
      }
      this.pageInfo =res.data
    },
    jumpExam(index){
      //模考
      if(this.isMoKao) {
        //清除考试缓存
        global.clearExamStorage();
        this.getMoExamQues(this.pageInfo[index].practice_id, this.pageInfo[index].pro_sub_id).then(res=>{
          if (this.ce_isCantExercise(res.set_id)) {
            this.ce_showCantExercisePop(res.set_id);
            return;
          }

          window.sessionStorage.setItem("isCanJumpExam", "true")
          const grantInfo = {
            practice_id:this.pageInfo[index].practice_id,
            pro_sub_id:this.pageInfo[index].pro_sub_id,
            exam_count:this.pageInfo[index].exam_count,
            grant_info: {
              title:this.pageInfo[index].title,
              exam_duration:res.exam_duration,
            }
          };
          window.localStorage.setItem("grantInfo", JSON.stringify(grantInfo))
          this.$router.replace({ name: "TYKSPage",query:{
            examType:global.ExamType.MoKao
          } });
        });
      } else {
        window.localStorage.setItem("grantID", this.pageInfo[index].grant_id)
        this.$router.push({ name: "TYKSStart" });
      }

    },
    async getMoExamQues(practice_id, pro_sub_id) {
      let params = {
        practice_id,
        pro_sub_id,
        load: true,
        isShowErr: true,
      }
      let res = await examinationApi.getMoExamQues(params)
      window.localStorage.setItem("exam_ques", JSON.stringify(res.data))
      window.localStorage.setItem("exam_id", res.data.exam_id)
      return res.data;
    },
    cantExerciseComfirm() {
      // this.$router.back()
      location.reload();
    }
  },
};
</script>
<style scoped>
.TYKS_page {
  width: 1200px;
  height: 80vh;
  margin: 0 auto;
  box-sizing: border-box;
  padding-top: 12px;
  overflow-y: auto;
}
.exam_item {
  width: 100%;
  height: 268px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.exam_item .img_box {
  width: 330px;
  height: 236px;
  flex-shrink: 0;
}
.exam_item .img_box img {
  width: 100%;
  height: 100%;
}
.info_box {
  flex: 1;
  box-sizing: border-box;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.info_box .base_info .title {
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
  font-size: 24px;
}
.info_box .base_info .detail {
  color: rgba(144, 147, 153, 1);
  font-size: 16px;
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  box-sizing: border-box;
  padding-right: 30px;
}

.subject_info {
  color: rgba(29, 37, 54, 1);
  font-size: 16px;
}
.subject_item {
  display: flex;
}
.subject_item div {
  margin-bottom: 10px;
  margin-right: 80px;
}

.jump_btn {
  width: 120px;
  height: 44px;
  display: flex;
  place-content: center;
  place-items: center;
  flex-shrink: 0;
  box-sizing: border-box;
  padding: 16px 20px;
  border-radius: 4px;
  background: rgba(100, 110, 255, 1);
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 20px;
  position: absolute;
  right: 0;
  bottom: 5px;
}

.jump_btn.disabled {
  width: auto;
  background-color: #C0C4CC;
}
</style>
