<template>
  <!--
    练题模式 ：选择后出答案 和解析 多选出按钮  答题卡：当前 正确 错误 未答
    测题模式 ：       选择后不出答案解析都不出 答题卡：当前 正确 错误 未答
    背题模式 ：全部结果都显示  答题卡：已答
-->
  <div class="exam_page" :class="[is1920 == true ? 'exam_page_1920' : '']" style="flex-direction: column;">
    <topbar :sub_ids="m_subList" @search="onSearch"/>
    <div class="exam_page" :class="[is1920 == true ? 'exam_page_1920' : '']">
      <div class="exam_box" :class="[is1920 == true ? 'exam_box_1920' : '']" style="margin-top: 16px !important;">
        <div class="left">

          <div v-show="!m_noData" class="ques_list" ref="ques_list" @scroll="handleScroll">

            <div :id="'question_' + questionWP.index" class="ques_item" v-for="questionWP in m_questions"
              :key="questionWP.question.id">


              <div class="left">
                <div class="title">
                  <div class="title_text">{{questionWP.question.ques_type_name}}
                    <div class="line"></div>
                  </div>

                  <div class="title_btn">
                    <div class="title_btn_item" @click="collectSubject(questionWP)">
                      <img src="../../img/eaxm_sc1.png" alt="" v-if="!questionWP.operation.isCollected">
                      <img src="../../img/eaxm_sc2.png" alt="" v-if="questionWP.operation.isCollected">
                      <div class="text">收藏</div>
                    </div>

                  </div>
                </div>

                <div class="ques_box">
                  <div class="subject_title" :style="{ fontSize: 18 + fontSizeNum + 'px' }">
                    <div>{{questionWP.index + 1}}.</div>
                    <div v-html="questionWP.question.question_title"></div>
                  </div>
                  <div class="subject_answer_list" :style="{ fontSize: 16 + fontSizeNum + 'px' }">
                    <div class="subject_answer_item" v-for="(item, index) in questionWP.question.test_ques" :key="index"
                       >
                      <div class="subject_answer_index"
                        :class="{subject_answer_correct:m_checkOptionSelected(questionWP,item) == 1, subject_answer_wrong:m_checkOptionSelected(questionWP,item) == -1, subject_answer_index_select:m_checkOptionSelected(questionWP,item) == 0}">
                        {{ questionWP.operationSymbols[index] }}
                      </div>
                      <div class="subject_answer_text"
                        :class="{subject_answer_correct:m_checkOptionSelected(questionWP,item) == 1, subject_answer_wrong:m_checkOptionSelected(questionWP,item) == -1, subject_answer_text_select:m_checkOptionSelected(questionWP,item) == 0}"
                        v-html="item.item"></div>
                    </div>
                  </div>
                </div>

                <div v-if="m_canUIShowAnsered(questionWP)" class="daan_jiexi">
                  <div class="daan_box">
                    <div class="title_row">
                      <div class="text_box">答案
                        <div class="line"></div>
                      </div>

                    </div>
                    <div class="daan_detail_box" :style="{ fontSize: 16 + fontSizeNum + 'px' }">
                      <div class="daan_detail_item">
                        <div class="text1">错误答案</div>
                        <div class="text2 text2_1 wrong_answers">{{m_wrongAnswers(questionWP)}}</div>
                      </div>
                      <div class="daan_detail_item">
                        <div class="text1"> </div>
                        <div class="text2 text2_2">

                        </div>
                      </div>
                      <div class="daan_detail_item">
                        <div class="text1">难度</div>
                        <div class="text2 text2_3">
                          <el-rate :value="questionWP.question.difficulty" disabled text-color="#ff9900">
                          </el-rate>
                        </div>
                      </div>
                    </div>
                    <div class="daan_count_box" :style="{ fontSize: 14 + fontSizeNum + 'px' }">
                      <div class="daan_count_item">
                        <div class="text1">学习正确次数：</div>
                        <div class="text2">{{questionWP.question.study_correct_num}}次</div>
                      </div>
                      <div class="daan_count_item">
                        <div class="text1">考试正确次数：</div>
                        <div class="text2">{{questionWP.question.exam_correct_num}}次</div>
                      </div>
                      <div class="daan_count_item">
                        <div class="text1">学习错误次数：</div>
                        <div class="text2">{{questionWP.question.study_err_num}}次</div>
                      </div>
                      <div class="daan_count_item">
                        <div class="text1">考试错误次数：</div>
                        <div class="text2">{{questionWP.question.exam_err_num}}次</div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="daan_box" style="border-top: 1px solid rgba(222, 229, 253, 1);">
                      <div class="title_row">
                        <div class="text_box">解析
                          <div class="line"></div>
                        </div>
                        <div class="check_wrong_box" @click="JXBoxFlexible(questionWP)">
                          <img src="../../img/jt_4.png" alt=""
                            :class="[!questionWP.operation.isShowAnalysis ? 'img_flexible' : '']">
                        </div>
                      </div>
                      <div class="jiexi_info_box">
                        <div class="jiexi_info" :class="[!questionWP.operation.isShowAnalysis ? 'jiexi_info_close' : '']"
                          :style="{ fontSize: 16 + fontSizeNum + 'px' }" v-html="questionWP.question.analysis">

                        </div>
                        <div class="add_book">添加笔记</div>
                      </div>
                    </div> -->
                </div>

              </div>

              <div class="seperate"></div>

              <div class="right">

                <div class="wrapper">
                  <div class="title">
                    解析
                  </div>
                  <div class="content" v-html="questionWP.question.analysis">

                  </div>
                </div>

              </div>
            </div>
          </div>
          <div v-show="m_noData" class="nodata">当前无错题数据</div>
        </div>
        <!-- <div class="right">


          <div class="card_box" :class="[is1920 == true ? 'card_box_1920' : '']">

          </div>
        </div> -->
      </div>



      <div class="page_end_fixed">
        <endBox></endBox>
      </div>


    </div>
  </div>
</template>

<script>
  import wrongCheckMixin from '@/mixins/wrong-check.js';
  import Timer from '@/utils/Timer.js';
  import comfirm from "../../components/comfirm.vue"
  import topbar from './components/topbar.vue';
  export default {
    mixins: [wrongCheckMixin],
    components: {
      comfirm,
      topbar
    },

    created() {
      this.m_init({
        beforSubmit: () => {

        },
        afterSubmit: (isSuccess) => {
          if (isSuccess) {
            this.$router.replace({
              path: "/Index/DKLXResultPage",
              query: {
                ...this.$route.query,
                ques_sum: this.m_pageResult.total,
                study_id: this.m_study_id
              },
            });
          }
        }
      });


      if (parseInt(window.screen.width) >= 1920) {
        this.is1920 = true
      } else {
        this.is1920 = false
      }



    },

    destroyed() {


    },

    mounted() {},
    data() {
      return {
        is1920: false,
        value: 3,
        pageInput: "",
        fontSizeNum: 0,
        count: {
          time: null,
          format: ['00', '00', '00']
        },
        isShowConfirmModal: false,
        confirmModalData: {
          // title: "确认清空答题记录",
          img: "https://resources.sxsaas.com/static/jigou-saas-app/comfrim_1.png",
          // info: "确认清空答题记录吗？",
          // data: {},
        },
      }
    },
    methods: {
      onSearch(data) {
        this.m_search = data;
        this.m_loadData(1);
      },

      handleScroll(event) {
        const limitation = 150;
        if (event.target.scrollTop >= this.$refs.ques_list.scrollHeight - this.$refs.ques_list.clientHeight - limitation) {
          this.m_nextPage();
        }
      },

      collectSubject(currentQuestion) {
        this.m_collect(currentQuestion);
      },

      showAnswer(currentQuestion) {
        if (!currentQuestion.operation.isAnswered)
          this.m_handleAnswered(currentQuestion);
        else
          currentQuestion.operation.isShowAnwser = true;
      },


      JXBoxFlexible(currentQuestion) {
        currentQuestion.operation.isShowAnalysis = !currentQuestion.operation.isShowAnalysis;
      },
    },
  };
</script>
<style scoped>
  .exam_page {
    width: 1200px;
    height: calc(100vh - 195px) !important;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-bottom:30px;
  }

  .exam_page_1920 {
    height: calc(100vh - 195px);
  }

  .exam_box {
    width: 100%;
    flex: 1;
    box-sizing: border-box;
    margin-top: 12px;
    display: flex;
    justify-content: space-between;

  }

  .exam_box_1920 {
    margin-top: 50px !important;
  }

  .exam_box>.left {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    background-color: #f5f7fb;

  }

  .exam_page .title_box {
    flex-shrink: 0;
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
    background-color: #fff;
    margin-bottom: 10px;
    font-size: 16px;
  }

  .title_box_1920 {
    height: 80px !important;
    font-size: 14px !important;
  }

  .exam_page .title_box .exam_name {
    display: flex;
    align-items: center;
  }

  .exam_page .title_box .exam_name .text1 {
    color: rgba(114, 123, 142, 1);
    font-size: 14px;
  }

  .exam_page .title_box .exam_name .text2 {
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
    font-size: 18px;
  }

  .clear_btn {
    padding: 6px 16px;
    border-radius: 4px;
    background: rgba(255, 95, 95, 1);
    display: flex;
    align-items: center;
    color: #fff;
    cursor: pointer;
  }

  .clear_btn.plain {
    background: none;
    border: 1px solid rgba(255, 95, 95, 1);
  }

  .clear_btn.plain .text {
    color: rgba(255, 95, 95, 1);
  }

  .clear_btn img {
    width: 18px;
    height: 18px;
    margin-right: 12px;
  }


  .ques_list {
    width: 100%;
    flex: 1;
    overflow-y: auto;
  }

  .ques_item {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: rgba(245, 247, 250, 1);
    margin-bottom: 16px;
    box-sizing: border-box;

  }

  .ques_item>.left {
    width: 853px;
    padding: 16px;
    padding-bottom:0;
    background-color: #fff;
  }

  .ques_item .seperate {
    width: 10px;
  }

  .ques_item>.right {
    position: relative;
    flex: 1;
    padding: 16px;
    background-color: #fff;
    overflow-y: auto;

    .wrapper {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 16px;
      left: 16px;
      right: 16px;
      bottom: 16px;

      .title {
        font-size: 20px;
        line-height: 28px;
        border: none;
      }

      .content {
        flex:1;
        overflow-y: auto;
        border-radius: 16px;
        font-size: 16px;
        background-color: #eef2fe;
        padding: 16px;
        line-height: 32px;

      }
    }


  }



  .ques_item .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 0 10px 10px;
    border-bottom: 1px solid rgba(222, 229, 253, 1);
  }

  .ques_item .title .title_text {
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
    font-size: 18px;
    position: relative;
  }

  .ques_item .title .title_text .line {
    width: 4px;
    height: 20px;
    border-radius: 11px;
    background: rgba(100, 110, 255, 1);
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .ques_item .title .title_btn,
  .ques_item .title .title_btn .title_btn_item {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .title_btn_item {
    margin-left: 26px;
  }

  .title_btn_item img {
    width: 24px;
    height: 24px;
  }

  .title_btn_item .text {
    color: rgba(114, 123, 142, 1);
    font-size: 16px;
    margin-left: 8px;
  }

  .ques_box {
    width: 100%;
    box-sizing: border-box;
    padding: 20px 0;

  }

  .ques_box .subject_title {
    color: rgba(0, 0, 0, 1);
    font-weight: 700;
    font-size: 18px;
    display: flex;
  }

  .ques_box .subject_title div:first-child {
    margin-right: 30px;
  }

  .subject_answer_list {
    margin-top: 25px;
    box-sizing: border-box;
    padding: 0 10px 0px 10px;
    font-size: 16px;
  }

  .subject_answer_item {
    display: flex;
    align-items: center;
    padding: 10px 10px;
    cursor: pointer;
  }



  .subject_answer_index {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: rgba(222, 229, 253, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
    flex-shrink: 0;
  }

  .subject_answer_index.subject_answer_correct {
    background-color: #29CB95 !important;
    color: #fff !important;
  }

  .subject_answer_index.subject_answer_wrong {
    background-color: #FF5F5F !important;
    color: #fff !important;
  }

  .subject_answer_index_select {
    background: rgba(100, 110, 255, 1) !important;
    color: #fff !important;
  }

  .subject_answer_text {
    color: rgba(29, 37, 54, 1);

    color: rgba(29, 37, 54, 1);
    word-wrap: break-word;
    /* 允许在长单词内部换行 */
    word-break: break-all;
    /* 允许在任意位置换行 */
  }

  .subject_answer_text_select {
    color: rgba(100, 110, 255, 1) !important;
  }

  .subject_answer_correct {
    color: #29CB95 !important;
  }

  .subject_answer_wrong {
    color: #FF5F5F !important;

  }

  .daan_jiexi {
    width: 100%;
    box-sizing: border-box;

    border-top: 1px solid rgba(222, 229, 253, 1);

  }

  .daan_box {

    width: 100%;
  }

  .daan_jiexi .title_row {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 0 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .daan_box .title_row .text_box {
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
    font-size: 18px;
    position: relative;

  }

  .title_row .text_box .line {
    width: 4px;
    height: 20px;
    border-radius: 11px;
    background: rgba(222, 229, 253, 1);
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .check_wrong_box {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .check_wrong_box img {
    width: 15px;
    height: 15px;
    transition: all 0.1s linear;
    transform: rotate(180deg);
  }

  .check_wrong_box .img_flexible {
    transform: rotate(0deg);
  }

  .check_wrong_box .text {
    color: rgba(60, 156, 255, 1);
    font-size: 12px;
  }

  .daan_detail_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 30px;
    font-size: 16px;
  }

  .daan_detail_item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .daan_detail_item .text1 {
    margin: 10px 0;
    color: rgba(29, 37, 54, 1);

  }

  .daan_detail_item .wrong_answers {
    color:#FF6C63 !important;
  }

  :deep(.el-rate__icon) {
    margin-right: 2px;
  }

  .daan_detail_item .text2 {
    font-weight: 500;
  }

  .daan_detail_item .text2_1 {
    color: rgba(41, 203, 149, 1);
  }

  .daan_detail_item .text2_2 {
    color: rgba(255, 95, 95, 1);
  }

  .daan_count_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 14px 0;
    box-sizing: border-box;
    padding: 0 4px;
    font-size: 14px;
  }

  .check_wrong_box img {
    width: 24px;
    height: 24px;
  }

  .daan_count_item {

    display: flex;
  }

  .daan_count_item .text1 {
    color: rgba(114, 123, 142, 1);
  }

  .daan_count_item .text2 {
    color: rgba(22, 27, 38, 1);
  }

  .jiexi_info_box {
    width: 100%;
    position: relative;
    padding-bottom: 30px;
  }

  .jiexi_info {
    color: rgba(29, 37, 54, 1);
    font-size: 16px;
    margin-top: 10px;
  }

  .jiexi_info_close {
    height: 0 !important;
    overflow: hidden !important;
  }

  .add_book {
    cursor: pointer;
    display: inline-block;
    padding: 2px 6px;
    border-radius: 10px;
    border: 0.5px solid rgba(60, 156, 255, 1);
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1);
    font-size: 12px;
    color: rgba(60, 156, 255, 1);
    position: absolute;
    right: 0;
    bottom: 0;
  }



  .tool_box_1920 {
    height: 200px !important;
    flex: none !important;
  }


  .tool_box {
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 16px 28px 20px 28px;
    background-color: #fff;
  }

  .tool_box .time {
    color: rgba(0, 0, 0, 1);
    font-weight: 700;
    font-size: 28px;
  }

  .tool_box .time.warning {
    color: rgb(255, 77, 53);
  }

  .progress_detail {
    width: 100%;
    margin-bottom: 12px;
  }

  .progress_detail .row_1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: rgba(100, 110, 255, 1);
    font-weight: 500;
    font-size: 14px;
  }

  :deep(.el-progress__text) {
    display: none;
  }

  :deep(.el-progress-bar) {
    padding: 0;
    margin-top: 2px;
  }

  .tool_box .set_font_box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .set_font_box img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .set_font_box .text {
    color: rgba(114, 123, 142, 1);
    font-size: 14px;
    margin: 0 12px;
  }

  .exam_box .right .card_box {
    width: 100%;
    flex: 1;
    background-color: #fff;
    box-sizing: border-box;
    padding: 10px 18px;
    min-height: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .card_box_1920 {
    padding: 20px 18px 25px 18px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .card_box .title {
    color: rgba(0, 0, 0, 1);
    font-weight: 700;
    font-size: 18px;
  }

  .card_type_list {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 10px;
    margin-top: 8px;
  }

  .card_type_item {
    display: flex;
    align-items: center;
  }

  .card_type_item .block {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid transparent;
    margin-right: 4px;
  }

  .card_type_item .text {
    color: rgba(29, 37, 54, 1);
    font-size: 14px;
  }

  .block_1 {
    border: 1px solid rgba(100, 110, 255, 1) !important;
  }

  .block_2 {
    border: 1px solid rgba(41, 203, 149, 1);
    background: rgba(41, 203, 149, 1);
  }

  .block_3 {
    border: 1px solid rgba(255, 201, 201, 1);
    background: rgba(255, 201, 201, 1);
  }

  .block_4 {
    border: 1px solid rgba(222, 229, 253, 1);
    background: rgba(222, 229, 253, 1);
  }

  .card_list {
    display: flex;
    flex: 1;
    flex-shrink: 0;
    min-height: 0;
    overflow-y: auto;
    margin-top: 10px;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(222, 229, 253, 1);
    align-content: flex-start
  }

  *::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }

  *::-webkit-scrollbar-thumb {
    background: rgba(230, 230, 232, 1);
    border-radius: 0;
  }

  *::-webkit-scrollbar-track {
    background-color: #fff;
    border: 1px solid #fff;
  }

  .ques_list::-webkit-scrollbar-thumb {
    width: 10px;
    height: 200px;
  }

  .ques_list::-webkit-scrollbar {
    width: 12px;
    /* 滚动条宽度， width：对应竖滚动条的宽度  height：对应横滚动条的高度*/

  }

  .card_item {
    width: 47px;
    height: 47px;
    position: relative;
    border-radius: 4px;
    border: 1px solid transparent;
    margin-right: 9px;
    margin-bottom: 9px;
  }

  .card_item .sign_num {
    font-size: 15px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .card_item .sign_num.correct {
    color: #fff;
  }

  .card_item .text {
    position: absolute;
    font-size: 14px;
    left: 2px;
    top: -1px;
    color: rgb(100, 110, 255);
  }

  .card_item .text.correct {
    color: #fff;
  }

  .card_item .mark_pic {
    width: 12px;
    height: 12px;
    position: absolute;
    right: 1px;
    bottom: -5px;
  }

  .page_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .page_box .page_btn {
    width: 80px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    font-size: 16px;
    border: 1px solid rgba(155, 178, 250, 1);
  }

  .page_input {
    display: flex;
    font-size: 14px;
    align-items: center;
  }

  .page_input span {
    margin: 0 6px;
  }

  .page_input input {
    width: 30px;
    height: 22px;
    border: 1px solid rgba(155, 178, 250, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .page_jump_btn {
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    background-color: rgba(100, 110, 255, 1);
    font-size: 10px;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    margin-left: 4px;
  }

  .submit_btn {
    width: 130px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
    background: rgba(100, 110, 255, 1);
    margin: 10px auto 0;
    cursor: pointer;
  }

  .submit_btn_1920 {
    height: 36px;
    line-height: 36px;
  }

  .page_input input:focus {
    outline: 1px solid rgba(155, 178, 250, 1);
    border-radius: 0;
  }

  .nodata {
    width: 100%;
    flex: 1;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: rgb(154, 154, 154);

  }
</style>
