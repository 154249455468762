import {
	baseURL
} from '../config/index.js'

import {
  request
} from "../utils/request"

//错题查看列表
export function viewList(data) {
  return request({
    url: 'stuwebapi/train.wrong_ques/viewList',
    method: 'post',
    data
  });
}

// 收藏
export function collect(data) {
  return request({
    url: 'stuwebapi/train.wrong_ques/collect',
    method: 'post',
    data
  });
}

//班级科目列表
export function subList(data) {
  return request({
    url: 'stuwebapi/train.wrong_ques/subList',
    method: 'post',
    data
  });
}

//计算题数
export function calculateQuesNum(data) {
  return request({
    url: 'stuwebapi/train.wrong_ques/calculateQuesNum',
    method: 'post',
    data,
    isLoading: data.load,
  });
}

//错题专考试题获取
export function getCuoTiZhuanKaoExamQues(data) {
  return request({
        url: 'stuwebapi/train.wrong_ques/startAnswer',
        method: 'post',
        data,
        isLoading: data.load,
        isShowMsg:data.isShowMsg,
        isShowErr:data.isShowErr
    })
}

//考试5道题一保存
export function examRecord(data) {
  return request({
        url: 'stuwebapi/train.wrong_ques/submitRecord',
        method: 'post',
        data
    })
}

//错题专考结果页
export function quesResult(data) {
  return request({
        url: 'stuwebapi/train.wrong_ques/quesResult',
        method: 'post',
        data
    })
}


//查看错题接口
export function checkErrors(data) {
  return request({
        url: 'stuwebapi/train.wrong_ques/checkErrors',
        method: 'post',
        data
    })
}

//错题专考错题查看数据导出
export function errDataExcel(data) {
  const query = Object.keys(data).filter(item=>data[item] != null).map(item=>item+'='+data[item]).join('&');
  return baseURL + 'stuwebapi/train.wrong_ques/errDataExcel?' + query;
}

//错题查看数据导出
export function viewErrQuesExcel(data) {
  const query = Object.keys(data).filter(item=>data[item] != null).map(item=>item+'='+data[item]).join('&');
  return baseURL + 'stuwebapi/train.wrong_ques/viewErrQuesExcel?' + query;
}
