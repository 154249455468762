<template>
  <div style="height: 100%">

    <div class="exam_page" :class="[is1920 == true ? 'exam_page_1920' : '']">
      <div class="exam_box" :class="[is1920 == true ? 'exam_box_1920' : '']">
        <div class="left">
          <topbar-cuoti v-if="m_isCuoTi" class="title_box"  :class="[is1920 ? 'title_box_1920' : '']" style="height:100px !important; " />
          <div v-else-if="!m_isZhuanKao && !m_isCuoTi && eaxmBase" class="title_box" :class="[is1920 ? 'title_box_1920' : '']">
            <div class="title_item">
              <div class="text1">考试名称 :</div>
              <div class="text2">{{ eaxmBase.grant_info.title }}</div>
            </div>
            <div class="title_item">
              <div class="text1">考试时长 :</div>
              <div class="text2">{{ eaxmBase.grant_info.exam_duration }}分钟</div>
            </div>
            <div class="title_item">
              <div class="text1">试题类型 :</div>
              <div class="text2">{{ m_isMoKao ? '模拟考试' : '统一考试' }}</div>
            </div>
            <div class="title_item">
              <div class="text1">已考次数 :</div>
              <div v-if="!m_isMoKao" class="text2">
                {{ eaxmBase.recordNum }}/{{ eaxmBase.grant_info.allow_num }}
              </div>
              <div v-else class="text2">
                {{ eaxmBase.exam_count }}
              </div>
            </div>
          </div>
          <div v-if="m_currentQuestion" :key="m_questionIndex" class="card_box" :class="[is1920 ? 'card_box_1920' : '']">
            <div class="subject_inof_box">
              <div class="sb_type">
                <div class="line"></div>
                <div class="text">
                  <span>{{ m_currentQuestion.question.ques_type_name }}</span>
                </div>
              </div>
              <div class="operation_btn_box flex_c">
                <div v-if="m_isMoKao || m_isCuoTi" class="operation_btn flex_c" @click="collectSubject">
                  <img src="../../img/eaxm_sc1.png" v-if="!m_currentQuestion.operation.isCollected" alt="">
                  <img src="../../img/eaxm_sc2.png" v-if="m_currentQuestion.operation.isCollected" alt="">
                  <div class="text">收藏</div>
                </div>
                <div class="operation_btn flex_c" @click="signSubject">
                  <img src="../../img/eaxm_mark1.png" v-if="!m_currentQuestion.operation.isSigned" alt="" />
                  <img src="../../img/eaxm_mark2.png" v-if="m_currentQuestion.operation.isSigned" alt="" />
                  <div class="text">标记</div>
                </div>
              </div>
            </div>
            <div class="subject_box" :style="{ fontSize: 16 + fontSizeNum + 'px' }"
              :class="[moveLeft == true ? 'card_box_left' : '', moveRight == true ? 'card_box_right' : '', { banner: true, animate: jumpSubject }]">
              <div class="matter_box">
                <div class="sort">{{ m_questionIndex + 1 }}.</div>
                <div v-html="m_currentQuestion.question.question_title"></div>
              </div>
              <div class="select_box">
                <div class="select_item" v-for="(item, index) in m_currentQuestion.question.test_ques" :key="index"
                  @click="selectSubject(item.is_id)">
                  <div class="btn" :class="[m_checkOptionSelected(item) ? 'btn_select' : '']"
                    :style="{ width: 32 + fontSizeNum + 'px', height: 32 + fontSizeNum + 'px' }">
                    {{ m_currentQuestion.operationSymbols[index] }}
                  </div>
                  <div v-html="item.item" class="text" :class="[m_checkOptionSelected(item) ? 'text_select' : '']">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="change_btn_box">
            <div class="btn btn_1" @click="changePre">上一题</div>
            <div class="btn btn_2" @click="openDialog">交卷</div>
            <div class="btn btn_1" @click="changeNext">下一题</div>
          </div>
        </div>
        <div class="right">
          <div class="progress_box">
            <div class="time" :class="[countDown.time != null && countDown.time <= 60 && !m_isCuoTi ? 'warning' : null]">
              {{ m_isZhuanKao  ? '' : countDown.format.join(':') }}
            </div>
            <div class="progress_detail">
              <div class="row_1">
                <div>{{ m_process.current }}/{{ m_process.total }}</div>
                <div>答题进度</div>
              </div>
              <div class="row_2">
                <el-progress :percentage="m_process.percentage" :stroke-width="10" color="#646eff"
                  define-back-color="#cdd8fd"></el-progress>
              </div>
            </div>
            <div class="set_font_box">
              <img src="../../img/exam_sub.png" @click="subFontSize" alt="" />
              <div class="text">字号设置</div>
              <img src="../../img/exam_add.png" @click="addFontSize" alt="" />
            </div>
          </div>
          <div class="subject_card">
            <div class="subject_card_title">
              <div class="card_title">答题卡</div>
              <div class="status_box">
                <div class="status_item">
                  <div class="coloer_block"></div>
                  当前
                </div>
                <div class="status_item">
                  <div class="coloer_block"></div>
                  已答
                </div>
                <div class="status_item">
                  <div class="coloer_block"></div>
                  未答
                </div>
              </div>
            </div>
            <div class="card_list">
              <div class="card_item" v-for="(item, index) in m_questionSheet"
                :class="[
                  !m_isQuestionAnswered(item) ? 'card_item_unanswer' : 'card_item_answer', m_questionIndex == index ? 'card_item_select' : '']" :key="index"
                @click="changeCard(index)">
                <div class="sign_num" :class="[
                  !m_isQuestionAnswered(item) ? 'card_unanswer_num' : 'card_answer_num',]">
                  {{ index + 1 }}
                </div>
                <div class="text" :class="[!m_isQuestionAnswered(item) ? 'card_unanswer_text' : 'card_answer_text',]">
                  <span v-if="item.question.ques_type == QuestTypez.SingleChoise">单</span>
                  <span v-if="item.question.ques_type == QuestTypez.MultipleChoise">多</span>
                  <span v-if="item.question.ques_type == QuestTypez.Judge">判</span>
                </div>
                <img class="mark_pic" src="../../img/eaxm_mark2.png" alt="" v-if="item.operation.isSigned" />
              </div>
            </div>
            <div class="card_line">
              <div class="line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :modal="true" :modal-append-to-body="false"
      :append-to-body="true">
      <span>确认结束答题，现在交卷？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitExam" v-throttle>确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="submitErrorDialogVisible" width="30%" :modal="true" :modal-append-to-body="false"
      :append-to-body="true" :show-close="canSubmitErrorDialogClose" :close-on-click-modal="canSubmitErrorDialogClose"
      @close="submitErrorDialogClose">
      <span>交卷出现问题，请联系管理员</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="submitExam">再次交卷</el-button>
      </span>
    </el-dialog>
    <div class="page_end_fixed">
      <endBox></endBox>
    </div>

  </div>
</template>

<script>
import examinationMixin from '@/mixins/examination.js';
import {
  _getExamInfo,
  _enterExam
} from "../../api/TYKSStart";
import DownTimer from '@/utils/DownTimer.js';
import Timer from '@/utils/Timer.js';
// import { _getVerList } from "../../api/version.js"
import TopbarCuoti from './components/topbar-cuoti.vue';
export default {
  mixins: [examinationMixin],
  components: {
    TopbarCuoti
  },
  created() {
    this.toggleFullScreen();
    if (parseInt(window.screen.width) >= 1920) {
      this.is1920 = true
    } else {
      this.is1920 = false
    }
    console.log(this.is1920);

    // const grant_id = localStorage.getItem("grantID");
    // this.m_storageKey = 'exam_storage_' + grant_id;
    //
    this.eaxmBase = JSON.parse(localStorage.getItem('grantInfo'));

    this.m_init({
      beforSubmit: () => {
        if (!this.m_isSubmitedByUser(this.m_isSubmiting))
          if (this.downTimer)
            this.downTimer.stop();
      },
      afterSubmit: (isSuccess) => {
        if (isSuccess) {
          this.toggleFullScreen()
          //为了让toggleFullScreen执行完
          setTimeout(()=>{
            window.sessionStorage.setItem("isCanJumpExam", "false")
            if (this.m_isCuoTi) {
              this.$router.replace({
                path: "/Index/WrongExamResult"
              });
            } else {
              this.$router.push({
                path: "/Index/ResultPage",
                query: {
                  ...this.$route.query
                }
              });
            }
          },500);
        } else {
          this.submitErrorDialogVisible = true;
          this.canSubmitErrorDialogClose = this.m_isSubmitedByUser(this.m_isSubmiting);
        }
      }
    });

    if(this.m_isCuoTi)
      this.startTimeCount();
    else if (!this.m_isZhuanKao && (this.m_lastSubmitType == null || this.m_isSubmitedByUser(this.m_lastSubmitType)))
      this.startTimeCountDown(this.eaxmBase.grant_info.exam_duration);


    if (this.m_isSubmited) {
      this.submitErrorDialogVisible = true;
      this.canSubmitErrorDialogClose = this.m_lastSubmitType == null || this.m_isSubmitedByUser(this.m_lastSubmitType);
    }

  },

  destroyed() {
    if (this.downTimer)
      this.downTimer.stop();
  },

  mounted() {
    window.onresize = () => {
      let isFull = document.mozFullScreen ||
        document.fullScreen ||
        //谷歌浏览器及Webkit内核浏览器
        document.webkitIsFullScreen ||
        document.webkitRequestFullScreen ||
        document.mozRequestFullScreen ||
        document.msFullscreenEnabled
      if (isFull === undefined) {
        this.fullscreen = false;
      }

    }
  },
  data() {
    return {
      fullscreen: false,
      is1920: false,
      dialogVisible: false,
      submitErrorDialogVisible: false,
      canSubmitErrorDialogClose: false,
      fontSizeNum: 0,
      eaxmBase: null,
      moveRight: false,
      moveLeft: false,
      jumpSubject: false,
      QuestTypez: {
        SingleChoise: 1,
        MultipleChoise: 2,
        Judge: 4
      },
      countDown: {
        time: null,
        format: ['00', '00', '00']
      },
    };
  },
  methods: {
    //开始倒计时
    startTimeCountDown(examDuration) {
      let startTime = this.m_cache.startTime;
      if (!startTime) {
        startTime = parseInt(Date.now() / 1000);
        this.m_saveStartTimeToCache(startTime);
      }
      const endTime = startTime + examDuration * 60;
      this.downTimer = new DownTimer(endTime, (value) => {
        this.countDown.time = value.time;
        this.countDown.format = value.format;
        if (value.time <= 0) {
          this.downTimer.stop();
          this.m_submit(this.m_SubmitType.TimeIsUp);
        }
      });
      this.downTimer.start();
    },

    startTimeCount() {
      let startTime = this.m_cache.startTime;
      if (!startTime) {
        startTime = parseInt(Date.now() / 1000);
        this.m_saveStartTimeToCache(startTime);
      }
      this.downTimer = new Timer(startTime, Timer.COUNT, (value) => {
      	this.countDown.time = value.time;
      	this.countDown.format = value.format;
      });
      this.downTimer.start();
    },

    // 题目的选项被点击  答题
    selectSubject(is_id) {
      this.m_answer(is_id);
    },
    // 切换题目 上一题
    changePre() {
      if (!this.m_canPrevious()) {
        this.$message({
          showClose: true,
          message: "已经是第一题了",
          type: "error",
          duration: 1500
        });
        return;
      }
      this.moveRight = true
      setTimeout(() => {
        this.moveRight = false
        this.m_previous()
      }, 200);

    },
    // 切换题目 下一题
    changeNext() {
      if (!this.m_canNext()) {
        this.$message({
          showClose: true,
          message: "已经是最后一题了",
          type: "error",
          duration: 1500
        });
        return;
      }


      this.moveLeft = true
      setTimeout(() => {
        this.moveLeft = false
        this.m_next()
      }, 200);
    },
    // 设置字体 加大
    addFontSize() {
      if (this.fontSizeNum >= 10) {
        return false;
      }
      this.fontSizeNum += 5;
    },
    // 设置字体 减小
    subFontSize() {
      if (this.fontSizeNum <= 0) {
        return false;
      }
      this.fontSizeNum -= 5;
    },
    // 答题卡 被点击 切换题目
    changeCard(index) {
      // 动画完成后，可以将isAnimating重置为false
      if (!this.m_canJump(index))
        return;
      this.m_jump(index);
      this.jumpSubject = true;
      setTimeout(() => {
        this.jumpSubject = false;

      }, 300);
    },
    // 标记题目
    signSubject() {
      this.m_sign();
    },

    //收藏题目
    collectSubject() {
      this.m_collect();
    },

    // 全屏函数 C V来的
    toggleFullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    openDialog() {
      this.dialogVisible = true
    },
    // 交卷
    submitExam() {
      console.log("交卷");
      this.dialogVisible = false;
      this.submitErrorDialogVisible = false;
      if (this.m_isSubmited) {
        this.m_submit(this.m_lastSubmitType, this.m_lastSubmitType == this.m_SubmitType.ForceEnd);
      } else {
        this.m_submit(this.m_SubmitType.UserSubmit);
      }
    },

    submitErrorDialogClose() {
      if (this.m_isSubmitedByUser(this.m_lastSubmitType)) {
        this.m_lastSubmitType = null;
        this.m_isSubmited = false;
      }
    },
  },
};
</script>
<style scoped>
.exam_page {
  width: 1200px;
  height: 90%;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.exam_page_1920 {
  height: 83% !important;
}

.exam_page .title_box {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px;
  background-color: #fff;
  margin-bottom: 10px;
  font-size: 12px;
}

.title_box_1920 {
  height: 80px !important;
  font-size: 14px !important;
}

.title_item {
  display: flex;
  align-items: center;
}

.title_item .text1 {
  color: rgba(114, 123, 142, 1);
}

.title_item .text2 {
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
  margin-left: 12px;
}

.exam_box {
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
}

.exam_box_1920 {
  margin-top: 60px !important;
}

.exam_box .left {
  width: 853px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card_box {
  width: 100%;
  height: 82%;
  background-color: #fff;
  box-sizing: border-box;
  margin-bottom: 12px;
  padding: 0px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.card_box_1920 {
  height: 72%;
}

.card_box .subject_inof_box {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(222, 229, 253, 1);
  padding-bottom: 8px;
  position: relative;
  z-index: 10;
  background-color: #fff;
}

.sb_type {
  display: flex;
  align-items: center;
}

.sb_type .line {
  width: 4px;
  height: 20px;
  border-radius: 11px;
  background: rgba(100, 110, 255, 1);
}

.sb_type .text {
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
  font-size: 18px;
  margin-left: 10px;
}

.operation_btn {
  cursor: pointer;
  margin-left: 40px;
}

.operation_btn img {
  width: 22px;
  height: 22px;
}

.operation_btn .text {
  color: rgba(114, 123, 142, 1);
  font-size: 16px;
  margin-left: 8px;
}

.subject_box {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: absolute;
  left: 0;
  box-sizing: border-box;
  padding: 40px 16px 0px 16px;
  transition: none;
}

.subject_box .matter_box {
  margin-top: 30px;
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
  display: flex;
  box-sizing: border-box;
  padding-left: 20px;
}

.matter_box .sort {
  padding-right: 30px;
}

.select_box {
  margin-top: 60px;
  box-sizing: border-box;
  padding: 0 10px 20px 10px;
}

.select_item {
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  padding: 20px 10px;
}

.select_box .select_item:nth-of-type(2n+1) {
  background: rgba(238, 242, 254, 1);
}

.select_box .select_item:nth-of-type(2n) {
  background: rgba(249, 250, 255, 1);
}

.select_item .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(222, 229, 253, 1);
  color: rgba(29, 37, 54, 1);

  margin-right: 40px;
  flex-shrink: 0;
}

.select_item .btn_select {
  background: rgba(100, 110, 255, 1) !important;
  color: #fff;
}

.select_item .text {
  color: rgba(29, 37, 54, 1);
  word-wrap: break-word;
  /* 允许在长单词内部换行 */
  word-break: break-all;
  /* 允许在任意位置换行 */

}

.select_item .text_select {
  color: rgba(100, 110, 255, 1) !important;
}

.change_btn_box {
  width: 100%;
  flex: 1;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 16px;
}

.change_btn_box .btn {
  padding: 7px 30px;
  border: 1px solid rgba(100, 110, 255, 1);
  border-radius: 4px;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 17px;
  cursor: pointer;
}

.change_btn_box .btn_1 {
  color: rgba(29, 37, 54, 1);
}

.change_btn_box .btn_2 {
  color: rgba(255, 255, 255, 1);
  background: rgba(100, 110, 255, 1);
}

.change_btn_box .btn_disable {
  border: 1px solid #ccc !important;
  background: #ccc !important;
  color: rgb(132, 132, 132) !important;
}

.exam_box .right {
  width: 334px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.progress_box {
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 16px 28px;
  background-color: #fff;
}

.progress_box .time {
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
  font-size: 28px;
}

.progress_box .time.warning {
  color: rgb(255, 77, 53);
}

.progress_detail {
  width: 100%;
  margin-bottom: 12px;
}

.progress_detail .row_1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: rgba(100, 110, 255, 1);
  font-weight: 500;
  font-size: 14px;
}

:deep(.el-progress__text) {
  display: none;
}

:deep(.el-progress-bar) {
  padding: 0;
  margin-top: 2px;
}

.progress_box .set_font_box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.set_font_box img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.set_font_box .text {
  color: rgba(114, 123, 142, 1);
  font-size: 14px;
  margin: 0 12px;
}

.exam_box .right .subject_card {
  height: 70%;
  width: 100%;
  background-color: #fff;
  margin-top: 12px;
  box-sizing: border-box;
  padding: 11px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.card_line {
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px;
  height: 20px;
  background-color: #fff;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
}

.card_line .line {
  width: 100%;
  height: 100%;
  border-top: 1px solid rgba(222, 229, 253, 1);
}

.subject_card .subject_card_title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 2px 3px;
}

.subject_card .subject_card_title .card_title {
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
  font-size: 16px;
}

.subject_card .subject_card_title .status_box {
  display: flex;
}

.status_box .status_item {
  display: flex;
  align-items: center;
  color: rgba(29, 37, 54, 1);
  font-size: 14px;
  margin-right: 16px;
}

.status_box .status_item .coloer_block {
  width: 14px;
  height: 14px;
  border-radius: 4px;
  box-sizing: border-box;
  margin-right: 3px;
}

.status_box .status_item:nth-of-type(1) .coloer_block {
  border: 1px solid rgba(100, 110, 255, 1);
}

.status_box .status_item:nth-of-type(2) .coloer_block {
  background: rgba(41, 203, 149, 1);
}

.status_box .status_item:nth-of-type(3) .coloer_block {
  background: rgba(222, 229, 253, 1);
}

.subject_card .card_list {
  width: 100%;
  height: 93%;
  box-sizing: border-box;
  padding: 16px 0 24px 0;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  align-content: flex-start;
}

.subject_card .card_list .card_item {
  width: 49px;
  height: 49px;
  border-radius: 4px;
  position: relative;
  box-sizing: border-box;
  border: 1px solid transparent;
  margin-right: 11px;
  margin-bottom: 11px;
  cursor: pointer;
}

.card_item_answer {
  background: rgba(41, 203, 149, 1);
}

.card_item_unanswer {
  background: rgba(222, 229, 253, 1);
}

.card_item_select {
  border: 1px solid rgba(100, 110, 255, 1) !important;
}

.card_item .sign_num {
  font-size: 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.card_item .text {
  position: absolute;
  font-size: 14px;
  left: 2px;
  top: -1px;
}

.card_item .mark_pic {
  width: 12px;
  height: 12px;
  position: absolute;
  right: 1px;
  bottom: -5px;
}

.card_answer_num {
  color: #fff;
}

.card_unanswer_num {
  color: #000;
}

.card_answer_text {
  color: #fff;
}

.card_unanswer_text {
  color: rgba(100, 110, 255, 1);
}

.card_list::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

.card_list::-webkit-scrollbar-thumb {
  background: rgba(230, 230, 232, 1);
  border-radius: 0;
}

.card_list::-webkit-scrollbar-track {
  background-color: #fff;
  border: 1px solid #fff;
}

.card_box_left {
  transition: all .3s linear;
  left: -100%;
}

.card_box_right {
  transition: all .2s linear;
  left: 100%;
}

.banner {
  /* 初始样式 */
}

.animate {
  /* 动画开始时的样式 */
  animation: bannerAnimation .3s forwards;
}

@keyframes bannerAnimation {
  from {
    /* 动画开始状态 */
    opacity: 0;
  }

  to {
    opacity: 1;
    /* 动画结束状态 */
  }
}</style>
