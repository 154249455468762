<template>
  <div class="Comfirm_shade_box" >
    <div class="Comfirm_box">
      <div class="title" v-if="data && data.title">{{ data.title }}</div>
      <div class="title" v-else>提示</div>
      <div class="img_box">
        <img v-if="data && data.img" :src="data.img" alt="">
        <!-- <img v-else src="../img/comfrim.png" alt=""> -->
        <div class="info_text" v-if="data && data.info">{{ data.info }}</div>
      </div>
      <div class="btn_box">
        <div class="quxiao com_btn" @click="cancel">取消</div>
        <div class="queding com_btn" @click="comfirm">确定</div>
      </div>
    </div>
  </div>
</template>
  

<script>

export default {
  name: "comfirm",
  props: {
    data: Object
  },
  components: {},
  created() {

  },

  mounted() {

  },
  data() {
    return {
      isShadeShow:true
    }
  },
  methods: {
    cancel() { 
      this.$emit('clickCancel', '这是子组件传递的数据--取消');
    },
    comfirm() { 
      this.$emit('clickComfirm', '这是子组件传递的数据--确定');
    },
  },
};
</script>

<style scoped>
.Comfirm_shade_box {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}

.Comfirm_box {
  width: 380px;
  height: 340px;
  border-radius: 20px;
  position: fixed;
  left: 50%;
  top: 48%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  overflow: hidden;
  position: relative;
}

.Comfirm_box .title {
  width: 100%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: rgba(100, 110, 255, 1);
  color: #fff;
  font-size: 18px;
}

.Comfirm_box .img_box {
  width: 170px;
  height: 170px;
  margin: 18px auto 30px;
  position: relative;
}

.Comfirm_box .img_box img {
  width: 100%;
  height: 100%;
}

.Comfirm_box .info_text {
  width: 360px;
  text-align: center;
  color: rgba(114, 123, 142, 1);
  font-size: 20px;
  position: absolute;
  left: 50%;
  top: 128px;
  transform: translate(-50%, 0);

}

.Comfirm_shade_box .btn_box {
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.Comfirm_shade_box .com_btn {
  width: 100px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  border: 1px solid rgba(122, 152, 249, 1);
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
}

.queding {
  color: #fff;
  background: rgba(100, 110, 255, 1);
  border: 1px solid rgba(100, 110, 255, 1);
}
</style>

