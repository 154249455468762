<template>
  <div style=" height: 100%;">
    <div class="result_box">
      <div class="left">
        <div class="press_box">
          <el-progress type="dashboard" :percentage="percentage" color="#695fee" :stroke-width="14" :show-text="false"
            define-back-color="#cdd8fd"></el-progress>
          <div class="info_box">
             <div class="point">{{ pageInfo.correct_rate }}%</div>
             <div class="text">正确率</div>
          </div>
        </div>
        <div class="submit-time">提交时间：{{pageInfo.submit_time}}</div>
        <div class="exam_info_box">
          <div class="exam_info_item">
            <div class="text1">{{ pageInfo.study_num }}</div>
            <div class="text2">已学习</div>
          </div>
          <div class="exam_info_item">
            <div class="text1">{{ pageInfo.ont_num }}</div>
            <div class="text2">未学习</div>
          </div>
          <div class="exam_info_item">
            <div class="text1">{{ pageInfo.correct_num }}</div>
            <div class="text2">正确题数</div>
          </div>
          <div class="exam_info_item">
            <div class="text1">{{ pageInfo.take_time }}</div>
            <div class="text2">用时</div>
          </div>
        </div>
      </div>
      <div class="right">


        <div class="title_box">
          <div class="text">详细信息</div>
          <div class="line"></div>
        </div>

        <div class="line" style="margin-bottom: 30px;">
          <div class="label">选择日期</div>
          <div class="value">{{wrongExamData.form.set_start_time ? wrongExamData.form.set_start_time : '-'}} ~ {{wrongExamData.form.set_end_time ? wrongExamData.form.set_end_time : '-'}}</div>
        </div>
        <div class="line" style="margin-bottom: 30px;">
          <div class="label">选择科目</div>
          <div class="value">{{wrongExamData.data.sub_ids.find(item=>item.id==wrongExamData.form.sub_id).sub_name}}</div>
        </div>
        <div class="extra" style="margin-bottom: 30px;">
          <div class="block">
            <div class="content">{{wrongExamData.data.wrong_types[wrongExamData.form.wrong_type]}}</div>
            <div class="title">选择错题</div>
          </div>
          <div class="block">
            <div class="content">{{wrongExamData.data.port_types[wrongExamData.form.port_type]}}</div>
            <div class="title">选择类型</div>
          </div>
          <div class="block">
            <div class="content">{{showTwoValues(wrongExamData.form.tiny_num,wrongExamData.form.big_num)}}</div>
            <div class="title">错题次数</div>
          </div>
          <div class="block">
            <div class="content">{{wrongExamData.form.ques_num}}</div>
            <div class="title">题目数量</div>
          </div>
        </div>



        <div class="btn_box">
          <div class="back_btn plain" @click="pageBack" v-throttle>返回班级</div>
          <div class="back_btn" @click="handleWrongCheck" v-throttle>查看错题</div>
          <div class="back_btn" @click="handleCuoTiZhuanKao" v-throttle>再练一组</div>
        </div>
      </div>
      <div class="line_box">
        <div class="line">
          <div class="yuan yuan1"></div>
          <div class="fang"></div>
          <div class="yuan yuan2"></div>
        </div>
        <div class="line">
          <div class="yuan yuan1"></div>
          <div class="fang"></div>
          <div class="yuan yuan2"></div>
        </div>
        <div class="line">
          <div class="yuan yuan1"></div>
          <div class="fang"></div>
          <div class="yuan yuan2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as wrongCheckApi from '@/api/wrongCheck.js';
  import * as global from '@/mixins/global.js';
  export default {
    components: {},
    created() {
      this.examResult()
    },

    mounted() {},
    data() {
      return {
        percentage: 0,
        pageInfo: {}
      };
    },
    computed: {
      wrongExamData: () => {
        return JSON.parse(window.sessionStorage.getItem("wrongExam"));
      }
    },
    methods: {
      async examResult() {
        let params = {
          errors_id: this.wrongExamData.errors_id,
          ques_num: this.wrongExamData.ques_num
        }
        let res = await wrongCheckApi.quesResult(params);
        this.pageInfo = res.data;

        this.percentage = this.pageInfo.correct_rate;
      },
      pageBack() {
        this.$router.replace({ path: "/Index/ExamTab" });
      },

      handleWrongCheck() {
        this.$router.replace({ name: "WrongCheckLookWrong" , query: {

                errors_id: this.wrongExamData.errors_id,

              }});
      },


      async handleCuoTiZhuanKao() {
        this.isShowCuotizhuankaoPop = false;
        const res = await wrongCheckApi.getCuoTiZhuanKaoExamQues({
          pro_id: window.localStorage.getItem("proID"),
          ...this.wrongExamData.form,
          load: true,
          isShowErr: true
        });

        res.data.ques.forEach(item => {
          item.ques_type = item.question_type;
          item.id = item.ques_id;
          return item;
        });

        global.clearExamStorage();
        window.sessionStorage.setItem("isCanJumpExam", "true");
        window.localStorage.setItem("exam_ques", JSON.stringify(res.data))
        window.localStorage.setItem("exam_id", res.data.errors_id)
        window.sessionStorage.setItem("wrongExam", JSON.stringify({...this.wrongExamData,errors_id:res.data.errors_id, ques_num:res.data.ques_num}));
        this.$router.replace({
          name: "TYKSPage",
          query: {
            examType: global.ExamType.CuoTi
          }
        });
      },

      showTwoValues(v1,v2) {
        if (v1 && v2)
          return v1 + ' - ' + v2;
        if (v1)
          return ' ≥ ' + v1;
        if (v2)
          return ' ≤ ' + v2;
        return ' - ';
      }
    },
  };
</script>
<style lang="less" scoped>
  .result_box {
    width: 58%;
    height: 63%;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .result_box>div {
    display: flex;
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    border-radius: 40px;
  }

  .result_box>.left {
    margin-right: 10px;
    background: linear-gradient(162.49deg, rgba(155, 178, 250, 1) 5.03%, rgba(211, 222, 255, 0.37) 111.82%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

  }

  .result_box>.right {
    margin-left: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }

  .result_box>.right .btn_box {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 47px;
  }

  .press_box {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .submit-time {
    margin: 22px 0;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
  }

  .info_box {
    width: 40%;
    height: 40%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 10px;
  }

  .info_box .point {
    color: rgba(100, 110, 255, 1);
    font-weight: 700;
    font-size: 25px;
  }

  .info_box .text {
    color: rgba(114, 123, 142, 1);
    font-size: 14px;
  }

  .exam_info_box {
    width: 90%;
    height: 108px;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 30px 10px 30px;
  }

  .exam_info_item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .exam_info_item .text1 {
    color: rgba(29, 37, 54, 1);
    font-size: 28px;
  }

  .exam_info_item .text2 {
    color: rgba(114, 123, 142, 1);
    font-size: 12px;
    margin-top: 14px;
  }

  .title_box {
    position: relative;
  }

  .title_box .text {
    color: rgba(0, 0, 0, 1);
    font-size: 20px;
    position: relative;
    z-index: 2;
  }

  .title_box .line {
    width: 112%;
    height: 17px;
    border-radius: 29px;
    background: rgba(155, 178, 250, 1);
    position: absolute;
    left: 50%;
    bottom: -2px;
    transform: translate(-50%, 0);
  }

  .point_box {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 60px;
    box-sizing: border-box;
    padding: 0 30px;
  }

  .point_item {
    width: 100%;

    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .point_item div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(114, 123, 142, 1);
    font-size: 16px;
  }

  .point_item .title {
    color: rgba(114, 123, 142, 1) !important;
    font-size: 20px !important;
  }

  .line_box {
    width: 110px;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 20px 0;
  }

  .line_box .line {
    width: 100%;
    height: 20px;
    position: relative;
  }

  .line_box .line .yuan {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: rgba(155, 178, 250, 1);
    position: absolute;
  }

  .line_box .line .yuan1 {

    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }

  .line_box .line .yuan2 {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }

  .line_box .line .fang {
    width: 102px;
    height: 13px;
    border-radius: 21px;
    background: rgba(100, 110, 255, 1);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .back_btn {

    width: 100px;
    height: 38px;
    display: flex;
    place-content: center;
    place-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    background: rgba(100, 110, 255, 1);
    color: #fff;
    align-self: flex-end;
    margin-top: 20px;
    cursor: pointer;
  }

  .back_btn.plain {
    background-color: #fff;
    border: solid 1px #7A98F9;
    color:#000;
  }

  .right {


    .title_box {
      margin-bottom: 57px;
    }

    &>.line {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-self: stretch;
      padding-left: 50px;


      .label {
        font-size: 16px;
        line-height: 22px;
        color: #A8ABB2;
      }

      .value {
        margin-left: 14px;
        font-size: 20px;
        line-height: 22px;
      }
    }

    .extra {
      align-self: stretch;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-left: 50px;

      .block {
        width: 33%;
        margin-bottom: 30px;

        .content {
          font-size: 20px;
          line-height: 22px;
        }

        .title {
          margin-top: 6px;
          font-size: 16px;
          line-height: 22px;
          color: #A8ABB2
        }
      }
    }
  }
</style>
