<template>
	<!--
    练题模式 ：选择后出答案 和解析 多选出按钮  答题卡：当前 正确 错误 未答
    测题模式 ：       选择后不出答案解析都不出 答题卡：当前 正确 错误 未答
    背题模式 ：全部结果都显示  答题卡：已答
-->
	<div class="exam_page" :class="[is1920 == true ? 'exam_page_1920' : '']">
		<div class="exam_box" :class="[is1920 == true ? 'exam_box_1920' : '']">
			<div class="left">
				<div class="title_box" :class="[is1920 ? 'title_box_1920' : '']">
					<div class="exam_name">
						<div class="text1">考试名称：</div>
						<div class="text2">{{m_sub_name}}</div>
					</div>
					<div style="display: flex;">
						<div v-if="m_isShowClearAllRecordBtn()" class="clear_btn" @click="m_showClearAllRecordModal">
							<img src="../../img/ljt.png" alt="">
							<div class="text">清除所有答题记录</div>
						</div>
						<div v-if="m_isShowClearPageRecordBtn()" style="margin-left: 10px;" class="clear_btn plain"
							@click="m_showClearPageRecordModal">
							<img src="../../img/ljt_red.png" alt="">
							<div class="text">清除本页答题记录</div>
						</div>
					</div>

				</div>
				<div class="ques_list">
					<div :id="'question_' + questionWP.index" class="ques_item" v-for="questionWP in m_questions"
						:key="questionWP.question.id">
						<div class="title">
							<div class="title_text">{{questionWP.question.ques_type_name}}
								<div class="line"></div>
							</div>

							<div class="title_btn">
								<div class="title_btn_item" @click="collectSubject(questionWP)">
									<img src="../../img/eaxm_sc1.png" alt="" v-if="!questionWP.operation.isCollected">
									<img src="../../img/eaxm_sc2.png" alt="" v-if="questionWP.operation.isCollected">
									<div class="text">收藏</div>
								</div>
								<div class="title_btn_item" @click="signSubject(questionWP)">
									<img src="../../img/eaxm_mark1.png" alt="" v-if="!questionWP.operation.isSigned" />
									<img src="../../img/eaxm_mark2.png" alt="" v-if="questionWP.operation.isSigned" />
									<div class="text">标记</div>
								</div>
							</div>
						</div>

						<div class="ques_box">
							<div class="subject_title" :style="{ fontSize: 18 + fontSizeNum + 'px' }">
								<div>{{questionWP.index + 1}}.</div>
								<div v-html="questionWP.question.question_title"></div>
							</div>
							<div class="subject_answer_list" :style="{ fontSize: 16 + fontSizeNum + 'px' }">
								<div class="subject_answer_item" v-for="(item, index) in questionWP.question.test_ques"
									:key="index" @click="selectSubject(questionWP, item.is_id)">
									<div class="subject_answer_index"
										:class="{subject_answer_correct:m_checkOptionSelected(questionWP,item) == 1, subject_answer_wrong:m_checkOptionSelected(questionWP,item) == -1, subject_answer_index_select:m_checkOptionSelected(questionWP,item) == 0}">
										{{ questionWP.operationSymbols[index] }}
									</div>
									<div class="subject_answer_text"
										:class="{subject_answer_correct:m_checkOptionSelected(questionWP,item) == 1, subject_answer_wrong:m_checkOptionSelected(questionWP,item) == -1, subject_answer_text_select:m_checkOptionSelected(questionWP,item) == 0}"
										v-html="item.item"></div>
								</div>
							</div>
						</div>

						<div v-if="m_showCheckAnswerButton(questionWP)" class="submit_btn"
							@click="showAnswer(questionWP)">查看答案
						</div>

						<div v-if="m_canUIShowAnsered(questionWP)" class="daan_jiexi">
							<div class="daan_box">
								<div class="title_row">
									<div class="text_box">答案
										<div class="line"></div>
									</div>
									<div class="check_wrong_box">
										<img src="../../img/wenhao.png" alt="">
										<div class="text">本题纠错</div>
									</div>
								</div>
								<div class="daan_detail_box" :style="{ fontSize: 16 + fontSizeNum + 'px' }">
									<div class="daan_detail_item">
										<div class="text1">正确答案</div>
										<div class="text2 text2_1">{{m_correctAnswers(questionWP)}}</div>
									</div>
									<div class="daan_detail_item">
										<div class="text1">你的答案</div>
										<div class="text2 text2_2"
											:class="[questionWP.operation.right_or_wrong == 1 ? 'subject_answer_correct' : 'subject_answer_wrong']">
											{{m_myAnswers(questionWP)}}
										</div>
									</div>
									<div class="daan_detail_item">
										<div class="text1">难度</div>
										<div class="text2 text2_3">
											<el-rate :value="questionWP.question.difficulty" disabled
												text-color="#ff9900">
											</el-rate>
										</div>
									</div>
								</div>
								<div class="daan_count_box" :style="{ fontSize: 14 + fontSizeNum + 'px' }">
									<div class="daan_count_item">
										<div class="text1">你已经做过这道题：</div>
										<div class="text2">{{m_answerStatistics(questionWP).total}}次</div>
									</div>
									<div class="daan_count_item">
										<div class="text1">答对：</div>
										<div class="text2">{{m_answerStatistics(questionWP).correct_num}}次</div>
									</div>
									<div class="daan_count_item">
										<div class="text1">答错：</div>
										<div class="text2">{{m_answerStatistics(questionWP).error_num}}次</div>
									</div>
									<div class="daan_count_item">
										<div class="text1">答对率：</div>
										<div class="text2">{{m_answerStatistics(questionWP).correct_rate}}%</div>
									</div>
								</div>
							</div>

							<div class="daan_box" style="border-top: 1px solid rgba(222, 229, 253, 1);">
								<div class="title_row">
									<div class="text_box">解析
										<div class="line"></div>
									</div>
									<div class="check_wrong_box" @click="JXBoxFlexible(questionWP)">
										<img src="../../img/jt_4.png" alt="" :class="[!questionWP.operation.isShowAnalysis ? 'img_flexible' : '']">
									</div>
								</div>
								<div class="jiexi_info_box">
									<div class="jiexi_info" :class="[!questionWP.operation.isShowAnalysis ? 'jiexi_info_close' : '']"
                  :style="{ fontSize: 16 + fontSizeNum + 'px' }"
										v-html="questionWP.question.analysis">

									</div>
									<div class="add_book">添加笔记</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="right">
				<div class="tool_box" :class="[is1920 == true ? 'tool_box_1920' : '']">
					<div class="time">
						{{ count.format.join(':') }}
					</div>
					<div class="progress_detail">
						<div v-if="m_study_type == m_StudyType.CuoTiZhuanKao" class="row_1">
							<div>错题总数</div>
							<div>{{m_process.total}}</div>
						</div>
						<div v-else class="row_1">
							<div>{{m_process.current}}/{{m_process.total}}</div>
							<div>答题进度</div>
						</div>
						<div v-if="m_study_type == m_StudyType.CuoTiZhuanKao" class="row_2">
							<el-progress :percentage="100" :stroke-width="10" color="#646eff"
								define-back-color="#cdd8fd"></el-progress>
						</div>
						<div v-else class="row_2">
							<el-progress :percentage="m_process.percentage" :stroke-width="10" color="#646eff"
								define-back-color="#cdd8fd"></el-progress>
						</div>
					</div>
					<div class="set_font_box">
						<img src="../../img/exam_sub.png" @click="subFontSize" alt="" />
						<div class="text">字号设置</div>
						<img src="../../img/exam_add.png" @click="addFontSize" alt="" />
					</div>
				</div>

				<div class="card_box" :class="[is1920 == true ? 'card_box_1920' : '']">
					<div class="title">答题卡</div>
					<div class="card_type_list">
						<div class="card_type_item">
							<div class="block block_1"></div>
							<div class="text">当前</div>
						</div>
						<div class="card_type_item">
							<div class="block block_2"></div>
							<div class="text">{{m_answerSheetCorrectLogoFont()}}</div>
						</div>
						<div v-if="m_showAnswerSheetErrorLogo()" class="card_type_item">
							<div class="block block_3"></div>
							<div class="text">错误</div>
						</div>
						<div class="card_type_item">
							<div class="block block_4"></div>
							<div class="text">未答</div>
						</div>
					</div>
					<div id="card_list_body" class="card_list">
						<div class="card_item"
							:class="[m_currentQuestion == m_questions[index] ? 'block_1' : null,m_answerSheetStatus(operation)]"
							v-for="({question, operation},index) in m_questions" :key="question.id"
							@click="jumpToQuestion(m_questions[index].index)">
							<div class="sign_num" :class="{correct: m_answerSheetFontShowCorrectColor(operation)}">
								{{ m_questions[index].index + 1 }}
							</div>
							<div class="text" :class="{correct: m_answerSheetFontShowCorrectColor(operation)}">
								<span v-if="question.ques_type == m_QuestType.SingleChoise">单</span>
								<span v-if="question.ques_type == m_QuestType.MultipleChoise">多</span>
								<span v-if="question.ques_type == m_QuestType.Judge">判</span>
							</div>
							<img class="mark_pic" src="../../img/eaxm_mark2.png" alt="" v-if="operation.isSigned" />
						</div>
					</div>
					<div class="page_box">
						<div class="page_btn" @click="previousPage">上一页</div>
						<div class="page_input">
							<input type="number" v-model="m_goPage">
							<span>/</span>
							<div>{{m_totalPage}}</div>
							<div class="page_jump_btn" @click="goPage">GO</div>
						</div>
						<div class="page_btn" @click="nextPage">下一页</div>
					</div>
					<div class="submit_btn" :class="[is1920 == true ? 'submit_btn_1920' : '']" @click="submit">保存并退出
					</div>
				</div>
			</div>
		</div>



		<div class="page_end_fixed">
			<endBox></endBox>
		</div>

		<comfirm @clickCancel="modalCancel" @clickComfirm="modalComfirm" :data="confirmModalData"
			v-if="isShowConfirmModal"></comfirm>
	</div>
</template>

<script>
	import exerciseMixin from '@/mixins/exercise.js';
  import cantExerciseMixin from '@/mixins/cant-exercise.js';
	import Timer from '@/utils/Timer.js';
	import comfirm from "../../components/comfirm.vue"
	export default {
		mixins: [exerciseMixin,cantExerciseMixin],
		components: {
			comfirm,
		},

		created() {
			this.m_init({
				beforSubmit: () => {

				},
				afterSubmit: (isSuccess) => {
					if (isSuccess) {
						this.$router.replace({
							path: "/Index/DKLXResultPage",
							query: {
								...this.$route.query,
								ques_sum: this.m_pageResult.total,
								study_id: this.m_study_id
							},
						});
					}
				}
			});

			this.startTimeCount();

			if (parseInt(window.screen.width) >= 1920) {
				this.is1920 = true
			} else {
				this.is1920 = false
			}

			this.m_openWebRefreshListener();

		},

		destroyed() {
			if (this.timer)
				this.timer.stop();
			this.m_closeWebRefreshListener();
		},

		mounted() {},
		data() {
			return {
				is1920: false,
				value: 3,
				pageInput: "",
				fontSizeNum: 0,
				count: {
					time: null,
					format: ['00', '00', '00']
				},
				isShowConfirmModal: false,
				confirmModalData: {
					// title: "确认清空答题记录",
					img: "https://resources.sxsaas.com/static/jigou-saas-app/comfrim_1.png",
					// info: "确认清空答题记录吗？",
					// data: {},
				},
			}
		},
		methods: {
			//开始计时
			startTimeCount() {
				let startTime = Date.now() / 1000;
				this.timer = new Timer(startTime, Timer.COUNT, (value) => {
					this.count.time = value.time;
					this.count.format = value.format;
				});
				this.timer.start();
			},

			// 设置字体 加大
			addFontSize() {
				if (this.fontSizeNum >= 10) {
					return false;
				}
				this.fontSizeNum += 5;
			},
			// 设置字体 减小
			subFontSize() {
				if (this.fontSizeNum <= 0) {
					return false;
				}
				this.fontSizeNum -= 5;
			},

			// 标记题目
			signSubject(currentQuestion) {
				this.m_sign(currentQuestion);
			},
			collectSubject(currentQuestion) {
				this.m_collect(currentQuestion);
			},
			previousPage() {
				this.m_previousPage();
			},
			nextPage() {
				this.m_nextPage();
			},
			goPage() {
				this.m_jumpPage(this.m_goPage);
			},
			submit() {
				this.confirmModalData = {
					...this.confirmModalData,
					title: '确认退出',
					info: '确认要退出吗？',
					confirm: () => {
						this.m_submit();
					},
				}
				this.isShowConfirmModal = true;
			},
			selectSubject(currentQuestion, is_id) {
				this.m_answer(currentQuestion, is_id);
				if (currentQuestion.question.ques_type == this.m_QuestType.SingleChoise || currentQuestion.question
					.ques_type ==
					this.m_QuestType.Judge) {
					this.m_handleAnswered(currentQuestion);
				}
			},
			showAnswer(currentQuestion) {
				if (!currentQuestion.operation.isAnswered)
					this.m_handleAnswered(currentQuestion);
				else
					currentQuestion.operation.isShowAnwser = true;
			},

			modalComfirm() {
				this.confirmModalData.confirm();
				this.modalCancel();
			},
			modalCancel() {
				this.isShowConfirmModal = false;
				this.confirmModalData = {
					img: this.confirmModalData.img
				};
			},
			jumpToQuestion(index) {
				this.m_jumpToQuestion(index);
			},
      JXBoxFlexible(currentQuestion) {
        currentQuestion.operation.isShowAnalysis = !currentQuestion.operation.isShowAnalysis;
      },
		},
	};
</script>
<style scoped>
	.exam_page {
		width: 1200px;
		height: 91% !important;
		margin: 0 auto;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		position: relative;
	}

	.exam_page_1920 {
		height: 83%;
	}

	.exam_box {
		width: 100%;
		flex: 1;
		box-sizing: border-box;
		margin-top: 12px;
		display: flex;
		justify-content: space-between;

	}

	.exam_box_1920 {
		margin-top: 50px !important;
	}

	.exam_box .left {
		width: 853px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		overflow: hidden;
		background-color: #f5f7fb;

	}

	.exam_page .title_box {
		flex-shrink: 0;
		width: 100%;
		height: 45px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-sizing: border-box;
		padding: 0 16px;
		background-color: #fff;
		margin-bottom: 10px;
		font-size: 16px;
	}

	.title_box_1920 {
		height: 80px !important;
		font-size: 14px !important;
	}

	.exam_page .title_box .exam_name {
		display: flex;
		align-items: center;
	}

	.exam_page .title_box .exam_name .text1 {
		color: rgba(114, 123, 142, 1);
		font-size: 14px;
	}

	.exam_page .title_box .exam_name .text2 {
		color: rgba(0, 0, 0, 1);
		font-weight: 500;
		font-size: 18px;
	}

	.clear_btn {
		padding: 6px 16px;
		border-radius: 4px;
		background: rgba(255, 95, 95, 1);
		display: flex;
		align-items: center;
		color: #fff;
		cursor: pointer;
	}

	.clear_btn.plain {
		background: none;
		border: 1px solid rgba(255, 95, 95, 1);
	}

	.clear_btn.plain .text {
		color: rgba(255, 95, 95, 1);
	}

	.clear_btn img {
		width: 18px;
		height: 18px;
		margin-right: 12px;
	}


	.ques_list {
		width: 100%;
		flex: 1;
		overflow-y: auto;
	}

	.ques_item {
		width: 100%;
		background-color: #fff;
		margin-bottom: 16px;
		box-sizing: border-box;
		padding: 16px;
	}

	.ques_item .title {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-sizing: border-box;
		padding: 0 0 10px 10px;
		border-bottom: 1px solid rgba(222, 229, 253, 1);
	}

	.ques_item .title .title_text {
		color: rgba(0, 0, 0, 1);
		font-weight: 500;
		font-size: 18px;
		position: relative;
	}

	.ques_item .title .title_text .line {
		width: 4px;
		height: 20px;
		border-radius: 11px;
		background: rgba(100, 110, 255, 1);
		position: absolute;
		left: -10px;
		top: 50%;
		transform: translate(0, -50%);
	}

	.ques_item .title .title_btn,
	.ques_item .title .title_btn .title_btn_item {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.title_btn_item {
		margin-left: 26px;
	}

	.title_btn_item img {
		width: 24px;
		height: 24px;
	}

	.title_btn_item .text {
		color: rgba(114, 123, 142, 1);
		font-size: 16px;
		margin-left: 8px;
	}

	.ques_box {
		width: 100%;
		box-sizing: border-box;
		padding: 20px 0;

	}

	.ques_box .subject_title {
		color: rgba(0, 0, 0, 1);
		font-weight: 700;
		font-size: 18px;
		display: flex;
	}

	.ques_box .subject_title div:first-child {
		margin-right: 30px;
	}

	.subject_answer_list {
		margin-top: 45px;
		box-sizing: border-box;
		padding: 0 10px 20px 10px;
		font-size: 16px;
	}

	.subject_answer_item {
		display: flex;
		align-items: center;
		padding: 20px 10px;
		cursor: pointer;
	}

	.subject_answer_list .subject_answer_item:nth-of-type(2n+1) {
		background: rgba(238, 242, 254, 1);
	}

	.subject_answer_list .subject_answer_item:nth-of-type(2n) {
		background: rgba(249, 250, 255, 1);
	}

	.subject_answer_index {
		width: 32px;
		height: 32px;
		border-radius: 50%;
		background: rgba(222, 229, 253, 1);
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 40px;
		flex-shrink: 0;
	}

	.subject_answer_index.subject_answer_correct {
		background-color: #29CB95 !important;
		color: #fff !important;
	}

	.subject_answer_index.subject_answer_wrong {
		background-color: #FF5F5F !important;
		color: #fff !important;
	}

	.subject_answer_index_select {
		background: rgba(100, 110, 255, 1) !important;
		color: #fff !important;
	}

	.subject_answer_text {
		color: rgba(29, 37, 54, 1);

		color: rgba(29, 37, 54, 1);
		word-wrap: break-word;
		/* 允许在长单词内部换行 */
		word-break: break-all;
		/* 允许在任意位置换行 */
	}

	.subject_answer_text_select {
		color: rgba(100, 110, 255, 1) !important;
	}

	.subject_answer_correct {
		color: #29CB95 !important;
	}

	.subject_answer_wrong {
		color: #FF5F5F !important;

	}

	.daan_jiexi {
		width: 100%;
		box-sizing: border-box;

		border-top: 1px solid rgba(222, 229, 253, 1);

	}

	.daan_box {

		width: 100%;
	}

	.daan_jiexi .title_row {
		width: 100%;
		box-sizing: border-box;
		padding: 10px 0 0 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;

	}

	.daan_box .title_row .text_box {
		color: rgba(0, 0, 0, 1);
		font-weight: 500;
		font-size: 18px;
		position: relative;

	}

	.title_row .text_box .line {
		width: 4px;
		height: 20px;
		border-radius: 11px;
		background: rgba(222, 229, 253, 1);
		position: absolute;
		left: -10px;
		top: 50%;
		transform: translate(0, -50%);
	}

	.check_wrong_box {
		cursor: pointer;
		display: flex;
		align-items: center;
	}

	.check_wrong_box img {
	    width: 15px;
	    height: 15px;
	    transition: all 0.1s linear;
	    transform: rotate(180deg);
	}

  .check_wrong_box .img_flexible {
      transform: rotate(0deg);
  }

	.check_wrong_box .text {
		color: rgba(60, 156, 255, 1);
		font-size: 12px;
	}

	.daan_detail_box {
		width: 100%;
		display: flex;
		justify-content: space-between;
		box-sizing: border-box;
		padding: 0 30px;
		font-size: 16px;
	}

	.daan_detail_item {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.daan_detail_item .text1 {
		margin: 10px 0;
		color: rgba(29, 37, 54, 1);

	}

	:deep(.el-rate__icon) {
		margin-right: 2px;
	}

	.daan_detail_item .text2 {
		font-weight: 500;
	}

	.daan_detail_item .text2_1 {
		color: rgba(41, 203, 149, 1);
	}

	.daan_detail_item .text2_2 {
		color: rgba(255, 95, 95, 1);
	}

	.daan_count_box {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin: 14px 0;
		box-sizing: border-box;
		padding: 0 4px;
		font-size: 14px;
	}

	.check_wrong_box img {
		width: 24px;
		height: 24px;
	}

	.daan_count_item {

		display: flex;
	}

	.daan_count_item .text1 {
		color: rgba(114, 123, 142, 1);
	}

	.daan_count_item .text2 {
		color: rgba(22, 27, 38, 1);
	}

	.jiexi_info_box {
		width: 100%;
		position: relative;
		padding-bottom: 30px;
	}

	.jiexi_info {
		color: rgba(29, 37, 54, 1);
		font-size: 16px;
		margin-top: 10px;
	}

  .jiexi_info_close {
      height: 0 !important;
      overflow: hidden !important;
  }

	.add_book {
		cursor: pointer;
		display: inline-block;
		padding: 2px 6px;
		border-radius: 10px;
		border: 0.5px solid rgba(60, 156, 255, 1);
		box-sizing: border-box;
		background: rgba(255, 255, 255, 1);
		font-size: 12px;
		color: rgba(60, 156, 255, 1);
		position: absolute;
		right: 0;
		bottom: 0;
	}

	.exam_box .right {
		flex: 1;
		height: 100%;

		margin-left: 16px;
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
	}

	.exam_box .right .tool_box {
		width: 100%;
		height: 144px;
		margin-bottom: 10px;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
	}

	.tool_box_1920 {
		height: 200px !important;
		flex: none !important;
	}


	.tool_box {
		width: 100%;
		height: 30%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		box-sizing: border-box;
		padding: 16px 28px 20px 28px;
		background-color: #fff;
	}

	.tool_box .time {
		color: rgba(0, 0, 0, 1);
		font-weight: 700;
		font-size: 28px;
	}

	.tool_box .time.warning {
		color: rgb(255, 77, 53);
	}

	.progress_detail {
		width: 100%;
		margin-bottom: 12px;
	}

	.progress_detail .row_1 {
		width: 100%;
		display: flex;
		justify-content: space-between;
		color: rgba(100, 110, 255, 1);
		font-weight: 500;
		font-size: 14px;
	}

	:deep(.el-progress__text) {
		display: none;
	}

	:deep(.el-progress-bar) {
		padding: 0;
		margin-top: 2px;
	}

	.tool_box .set_font_box {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.set_font_box img {
		width: 24px;
		height: 24px;
		cursor: pointer;
	}

	.set_font_box .text {
		color: rgba(114, 123, 142, 1);
		font-size: 14px;
		margin: 0 12px;
	}

	.exam_box .right .card_box {
		width: 100%;
		flex: 1;
		background-color: #fff;
		box-sizing: border-box;
		padding: 10px 18px;
		min-height: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.card_box_1920 {
		padding: 20px 18px 25px 18px !important;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.card_box .title {
		color: rgba(0, 0, 0, 1);
		font-weight: 700;
		font-size: 18px;
	}

	.card_type_list {
		display: flex;
		justify-content: space-between;
		box-sizing: border-box;
		padding: 0 10px;
		margin-top: 8px;
	}

	.card_type_item {
		display: flex;
		align-items: center;
	}

	.card_type_item .block {
		width: 16px;
		height: 16px;
		border-radius: 4px;
		box-sizing: border-box;
		border: 1px solid transparent;
		margin-right: 4px;
	}

	.card_type_item .text {
		color: rgba(29, 37, 54, 1);
		font-size: 14px;
	}

	.block_1 {
		border: 1px solid rgba(100, 110, 255, 1) !important;
	}

	.block_2 {
		border: 1px solid rgba(41, 203, 149, 1);
		background: rgba(41, 203, 149, 1);
	}

	.block_3 {
		border: 1px solid rgba(255, 201, 201, 1);
		background: rgba(255, 201, 201, 1);
	}

	.block_4 {
		border: 1px solid rgba(222, 229, 253, 1);
		background: rgba(222, 229, 253, 1);
	}

	.card_list {
		display: flex;
		flex: 1;
		flex-shrink: 0;
		min-height: 0;
		overflow-y: auto;
		margin-top: 10px;
		flex-wrap: wrap;
		border-bottom: 1px solid rgba(222, 229, 253, 1);
		align-content: flex-start
	}

	*::-webkit-scrollbar {
		width: 4px;
		height: 5px;
	}

	*::-webkit-scrollbar-thumb {
		background: rgba(230, 230, 232, 1);
		border-radius: 0;
	}

	*::-webkit-scrollbar-track {
		background-color: #fff;
		border: 1px solid #fff;
	}

	.ques_list::-webkit-scrollbar-thumb {
		width: 10px;
		height: 200px;
	}

	.ques_list::-webkit-scrollbar {
		width: 12px;
		/* 滚动条宽度， width：对应竖滚动条的宽度  height：对应横滚动条的高度*/

	}

	.card_item {
		width: 47px;
		height: 47px;
		position: relative;
		border-radius: 4px;
		border: 1px solid transparent;
		margin-right: 9px;
		margin-bottom: 9px;
	}

	.card_item .sign_num {
		font-size: 15px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.card_item .sign_num.correct {
		color: #fff;
	}

	.card_item .text {
		position: absolute;
		font-size: 14px;
		left: 2px;
		top: -1px;
		color: rgb(100, 110, 255);
	}

	.card_item .text.correct {
		color: #fff;
	}

	.card_item .mark_pic {
		width: 12px;
		height: 12px;
		position: absolute;
		right: 1px;
		bottom: -5px;
	}

	.page_box {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-top: 10px;
	}

	.page_box .page_btn {
		width: 80px;
		height: 24px;
		text-align: center;
		line-height: 24px;
		font-size: 16px;
		border: 1px solid rgba(155, 178, 250, 1);
	}

	.page_input {
		display: flex;
		font-size: 14px;
		align-items: center;
	}

	.page_input span {
		margin: 0 6px;
	}

	.page_input input {
		width: 30px;
		height: 22px;
		border: 1px solid rgba(155, 178, 250, 1);
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	.page_jump_btn {
		width: 24px;
		height: 24px;
		text-align: center;
		line-height: 24px;
		background-color: rgba(100, 110, 255, 1);
		font-size: 10px;
		border-radius: 4px;
		color: #fff;
		cursor: pointer;
		margin-left: 4px;
	}

	.submit_btn {
		width: 130px;
		height: 30px;
		text-align: center;
		line-height: 30px;
		color: #fff;
		font-size: 16px;
		border-radius: 4px;
		background: rgba(100, 110, 255, 1);
		margin: 10px auto 0;
		cursor: pointer;
	}

	.submit_btn_1920 {
		height: 36px;
		line-height: 36px;
	}

	.page_input input:focus {
		outline: 1px solid rgba(155, 178, 250, 1);
		border-radius: 0;
	}
</style>
