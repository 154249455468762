import {
  Loading,
  Message
} from 'element-ui';

import router from '@/router';


export const Config = {
  MaxPostQuestionNum: 10, //10条记录一保存
  MaxSubmitShowTipTime: 2, //交卷时给用户显示“已交卷”的提示时间
  MaxSubmitTime: 3, //交卷提交接口异常时，最多尝试次数
  MaxAnswersSaveTime: 3, //交卷前提交保存答题记录异常时，最多尝试次数
  PageQuestionNum: 50, //每页显示的记录数
}

//学习类型
export const StudyType = {
  QuanTiKu: 1, //全题库
  ZhangJie: 2, //章节
  TiXingZhuanKao: 3, //题型专考  单选题  多选题  判断题
  CuoTiZhuanKao: 4, //错题专考
  ShouCangZhuanKao: 5, //收藏专考
};

//答题模式
export const PatternType = {
  BeiTi: 1, //背题
  LianTi: 2, //练题
  CeTi: 3 //测题
}

//问题类型
export const QuestType = {
  SingleChoise: 1, //单选
  MultipleChoise: 2, //多选
  Judge: 4 //判断
};

//考试类型
export const ExamType = {
  TongKao: 1, //统考
  MoKao: 2, //模拟考试
  ZhuanKao: 3, //个人专考
  CuoTi:4, //错题专考
};

//考试交卷类型
export const ExamSubmitType = {
  // 1:用户提交 2:前端强制交卷（考试时间到） 3:切换应用自动提交 4：结束按钮
  UserSubmit: 1,
  TimeIsUp: 2,
  AppSwitchBack: 3,
  ForceEnd: 4,
}

export function showLoading(title) {
  const loadingInstance = Loading.service({
    text: title,
    background: "rgba(0,0,0,.3)"
  });
  return loadingInstance;
}

export function showMessage(message, type = "error") {
  Message({
    showClose: true,
    message: message,
    type: type,
  });
}

//删除统考和模考的缓存
export function clearExamStorage() {
  localStorage.removeItem('exam_storage');
  localStorage.removeItem("exam_ques");
  localStorage.removeItem('grantInfo');
}

//退出登录
export function logout() {
  window.localStorage.clear()
  router.replace({
    path: "/"
  });
}

//浏览器退出全屏
export function exitFullScreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) { // Firefox
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) { // Internet Explorer and Edge
    document.msExitFullscreen();
  }
}


export function formatTime(date) {
  if (!date)
    return '';
  date = new Date(date);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2,'0');
  const day = date.getDate().toString().padStart(2,'0');
  return [year,month,day].join('-');
}
