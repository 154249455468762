import * as exerciseApi from '@/api/exercise.js';

export default {
  data() {
    return {
      ce_isShowCantExerciseModal: false,
      ce_cantExerciseComfirmData: {
        title: '当前时间不允许练题',
        infos: []
      }
    }
  },
  methods: {
    ce_isCantExercise(set_id) {
      return set_id != 0;
    },
    async ce_showCantExercisePop(set_id) {
      const cantExerciseSetRes = await exerciseApi.cantExerciseSetPopout({
        set_id
      });
      this.ce_cantExerciseComfirmData.infos = cantExerciseSetRes.data.length > 0 ? ['当前允许练习时间', ...cantExerciseSetRes
        .data
      ] : [];
      this.ce_isShowCantExerciseModal = true;
    }
  }
}
