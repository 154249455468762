<template>
  <div class="Comfirm_shade_box" :style="{height:`calc(100% - ${top}px); top:${top}px`}">
    <div class="Comfirm_box">
      <template>
        <div class="title" v-if="data && data.title">{{ data.title }}</div>
        <div class="title" v-else>提示</div>
      </template>
      <div class="text_box">
        <template v-if="data && data.infos">
          <div v-for="info in data.infos" class="info_text">{{ info }}</div>
        </template>
      </div>
      <div class="btn_box">
        <div class="queding com_btn" @click="comfirm">知道了</div>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: "comfirm",
  props: {
    data: Object,
    top:0,
  },
  components: {},
  created() {

  },

  mounted() {

  },
  data() {
    return {
      isShadeShow:true
    }
  },
  methods: {
    cancel() {
      this.$emit('clickCancel', '这是子组件传递的数据--取消');
    },
    comfirm() {
      this.$emit('clickComfirm', '这是子组件传递的数据--确定');
    },
  },
};
</script>

<style scoped>
.Comfirm_shade_box {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
}

.Comfirm_box {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  width: 380px;
  min-height: 200px;
  border-radius: 20px;
  position: fixed;
  left: 50%;
  top: 48%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  overflow: hidden;
  position: relative;
}

.Comfirm_box .title {
  width: 100%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: rgba(100, 110, 255, 1);
  color: #fff;
  font-size: 18px;
}

.Comfirm_box .text_box {
  flex:1;
  align-self: stretch;
  padding-top:20px;
  padding-bottom:20px;
}



.Comfirm_box .info_text {
  width:100%;
  text-align: center;
  color: rgba(114, 123, 142, 1);
  font-size: 20px;
  line-height: 40px;
}

.Comfirm_shade_box .btn_box {
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.Comfirm_shade_box .com_btn {
  width: 100px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  border: 1px solid rgba(122, 152, 249, 1);
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
}

.queding {
  color: #fff;
  background: rgba(100, 110, 255, 1);
  border: 1px solid rgba(100, 110, 255, 1);
}
</style>

