<template>
  <div class="tobbar">
    <div class="title-block">
      <div class="title">试题信息</div>
    </div>

    <el-form :inline="true" class="search-block">
      <el-form-item>
        <el-input prefix-icon="el-icon-date" class="form-item" :value="showTwoValues(wrongExamData.form.set_start_time,wrongExamData.form.set_end_time)" style="width:200px !important;"
          size="small" readonly></el-input>
      </el-form-item>

      <el-form-item>
        <el-input class="form-item"
          :value="wrongExamData.data.sub_ids.find(item=>item.id==wrongExamData.form.sub_id).sub_name" size="small"
          readonly></el-input>
      </el-form-item>
      <el-form-item>
        <el-input class="form-item" :value="wrongExamData.data.wrong_types[wrongExamData.form.wrong_type]" size="small"
          readonly></el-input>
      </el-form-item>
      <el-form-item>
        <el-input class="form-item" :value="wrongExamData.data.port_types[wrongExamData.form.port_type]" size="small"
          readonly></el-input>
      </el-form-item>
      <el-form-item>
        <el-input class="form-item" :value="showTwoValues(wrongExamData.form.tiny_num,wrongExamData.form.big_num)" size="small"
          style="width: 80px !important;" readonly></el-input>
      </el-form-item>

    </el-form>
  </div>

</template>

<script>
  export default {
    props: ['sub_ids'],

    computed: {
      wrongExamData: () => {
        return JSON.parse(window.sessionStorage.getItem("wrongExam"));
      }
    },
    data() {
      return {
        form: {
          start_time: null,
          end_time: null,
          wrong_type: '0',
          sub_id: '0',
          port_type: '0',
        }
      }
    },
    methods: {
      showTwoValues(v1,v2) {
        if (v1 && v2)
          return v1 + ' - ' + v2;
        if (v1)
          return ' ≥ ' + v1;
        if (v2)
          return ' ≤ ' + v2;
        return ' - ';
      }
    }

  }
</script>

<style>
  .tobbar .el-input__prefix {
    margin-top: -5px;
  }
</style>
<style lang="less" scoped>
  .tobbar {
    display: flex;
    flex-direction: column;
    padding: 15px 10px 5px !important;
    align-items: flex-start !important;
    background-color: #fff;

    .title-block {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: 20px;
        line-height: 22px;
        color: #000;
      }
    }
  }

  .form-item {
    width: 137px !important;
    height: 32px !important;
  }

  .el-form-item {
    margin-bottom: 5px;
  }
</style>
