<template>
  <div class="tobbar">
    <div class="title-block">
      <div class="title">{{user.stu_name}}的错题集</div>
      <div class="right">
        <el-button plain class="export" type="primary" @click="exportQues">错题集导出</el-button>
        <div style="position: relative;width:120px;">
          <backBtn class="back" style="position:absolute"></backBtn>
          <div style="position: absolute;z-index: 1;left:15px; height:40px;width:100px;cursor: pointer;" @click="back">
          </div>
        </div>
      </div>
    </div>

    <el-form :inline="true" class="search-block">
      <el-form-item>
        <el-date-picker v-model="form.set_start_time" value-format="yyyy-MM-dd" class="form-item" type="date"
          placeholder="选择开始日期" size="small" style="width:145px !important">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-date-picker v-model="form.set_end_time" value-format="yyyy-MM-dd" class="form-item" type="date"
          placeholder="选择结束日期" size="small" style="width:145px !important">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.wrong_type" class="form-item" size="small">
          <el-option label="全部错题" value="0"></el-option>
          <el-option label="当前错题" value="1"></el-option>
          <el-option label="历史错题" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.sub_id" class="form-item" size="small">
          <el-option label="全部科目" value="0"></el-option>
          <el-option v-for="item in sub_ids" :label="item.sub_name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.port_type" class="form-item" size="small">
          <el-option label="全部类型" value="0"></el-option>
          <el-option label="练习" value="1"></el-option>
          <el-option label="考试" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch" size="small" icon="el-icon-search"> </el-button>
      </el-form-item>
    </el-form>
  </div>

</template>

<script>
  import * as wrongCheckApi from '@/api/wrongCheck.js';
  import backBtn from "@/components/backBtn";
  import * as global from '@/mixins/global.js';
  export default {
    props: ['sub_ids'],
    components: {
      backBtn
    },
    data() {
      return {
        form: {
          set_start_time: null,
          set_end_time: null,
          wrong_type: '0',
          sub_id: '0',
          port_type: '0',
        }
      }
    },
    computed: {
      user() {
        return JSON.parse(window.localStorage.getItem('user'));
      }
    },
    methods: {
      back(event) {
        this.$router.replace({ name: "ExamTab" });
        event.stopPropagation();
      },
      exportQues() {
        const url = wrongCheckApi.viewErrQuesExcel({
          pro_id: localStorage.getItem('proID'),
          stu_id: this.user.stu_id,
          ...this.form
        });
        window.open(url);
      },
      onSearch() {
        if (!this.verification())
          return;
        this.$emit('search', this.form);
      },
      verification() {
        if (this.form.set_start_time && this.form.set_end_time) {
          if (new Date(this.form.set_end_time) < new Date(this.form.set_start_time)) {
            global.showMessage('结束日期不能小于开始日期');
            return false;
          }
        }
        return true;
      }
    }

  }
</script>
<style>
  .tobbar .el-input__suffix {
    margin-top: -5px;
  }
</style>
<style lang="less" scoped>
  .tobbar {
    padding: 10px 10px 5px;
    margin-top: 20px;
    background-color: #fff;

    .title-block {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      .title {
        font-size: 18px;
        line-height: 24px;
        color: #727B8E;
      }
    }

    .search-block {}

    .right {
      display: flex;
      flex-direction: row;

      .export {
        color: #000;
        background-color: #fff;
        border: 1px solid rgba(122, 152, 249, 1);
      }

      .back {
        margin-left: 16px;
      }
    }

  }

  .form-item {
    width: 137px !important;
    height: 32px !important;
  }

  .el-form-item {
    margin-bottom: 5px;
  }
</style>
