import {
  request
} from "../utils/request"

//获取默认的科目
export function subjectInfo(data) {
  return request({
    url: 'stuwebapi/train.subject/trainSubinfo',
    method: 'post',
    data
  });
}

//获取科目列表
export function subject(data) {
  return request({
    url: 'stuwebapi/train.subject/subList',
    method: 'post',
    data
  });
}

//获取数据分析
export function dataAnalysis(data) {
  return request({
    url: 'stuwebapi/train.data_list/dataAnalysis',
    method: 'post',
    data
  });
}

//获取章节列表
export function unitList(data) {
  return request({
    url: 'stuwebapi/train.data_list/unitList',
    method: 'post',
    data
  });
}

//获取题型练习列表
export function quesTypeList(data) {
  return request({
    url: 'stuwebapi/train.data_list/quesTypeList',
    method: 'post',
    data
  });
}

//创建题并且取题
export function studyCreate(data) {
  return request({
    url: 'stuwebapi/train.test/studyCreate',
    method: 'post',
    data
  });
}

//分页取题
export function pagingQuesList(data) {
  return request({
    url: 'stuwebapi/train.test/pagingQuesList',
    method: 'post',
    data
  });
}

//10题保存
export function studyRecord(data) {
  return request({
    url: 'stuwebapi/train.test/studyRecord',
    method: 'post',
    data
  });
}

//收藏
export function collect(data) {
  return request({
    url: 'stuwebapi/train.test/collect',
    method: 'post',
    data
  });
}

//清除答题记录或开始新一轮答题接口
export function studyWheel(data) {
  return request({
    url: 'stuwebapi/train.test/studyWheel',
    method: 'post',
    data
  });
}


//获取试题开放设置弹框接口
export function cantExerciseSetPopout(data) {
  return request({
    url: 'stuwebapi/train.test/setPopout',
    method: 'post',
    data
  });
}
