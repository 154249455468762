<template>
  <div>
    <div class="back_box">
      <div class="back_btn" @click="backPage">
        <img src="https://resources.sxsaas.com/static/jigou-saas-app/jiantou.png" alt="">
        <div class="text">返回</div>
      </div>
      <div class="class_name">{{ className }}</div>
    </div>
    <div class="main_box">
      <div class="left">
        <div class="exam_item" :class="{invisible:!item.text}" v-for="(item, index) in examArr" :key="index" @click="jumpPage(index)" v-throttle>
          <img :src="item.pic" alt="" />
          <div class="text">{{ item.text }}</div>
        </div>
      </div>
      <div class="right">
        <div class="title">考试成绩</div>
        <div class="btn_box">
          <div class="btn_item" v-for="(item, index) in examBtnArr" @click="lookSubject(index)">
            <img :src="item.pic" alt="" />
            <div class="text">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="page_end_fixed">
      <endBox></endBox>
    </div>

    <!-- <comfirm2  @clickComfirm="cantExerciseComfirm" :data="ce_cantExerciseComfirmData" v-if="ce_isShowCantExerciseModal"></comfirm2> -->
    <cuotizhuankao-pop v-if="isShowCuotizhuankaoPop" @close="isShowCuotizhuankaoPop=false" @start="handleCuoTiZhuanKao" ></cuotizhuankao-pop>
  </div>
</template>

<script>
import { _getDateList,_getDateListStudy } from "../api/bigScreen.js"
import * as global from '@/mixins/global.js';
import * as examinationApi from '@/api/examination.js';
import * as wrongCheckApi from '@/api/wrongCheck.js';
import CuotizhuankaoPop from '@/components/cuotizhuankao-pop.vue';


export default {
  components: {
    CuotizhuankaoPop
  },
  created() {
    if (this.$route.params) {
      this.className = this.$route.params.className
    }

  },

  mounted() { },
  data() {
    return {
      className: "",
      examBtnArr: [
        {
          text: "模考成绩",
          pic: "https://resources.sxsaas.com/static/jigou-saas-app/indexBtn1.png",
        },
        {
          text: "统考成绩",
          pic: "https://resources.sxsaas.com/static/jigou-saas-app/indexBtn2.png",
        },
      ],
      examArr: [
        {
          text: "单科练习",
          pic: "https://resources.sxsaas.com/static/jigou-saas-app/class1.png",
        },
        {
          text: "综合模拟",
          pic: "https://resources.sxsaas.com/static/jigou-saas-app/class2.png",
        },
        {
          text: "统一考试",
          pic: "https://resources.sxsaas.com/static/jigou-saas-app/class3.png",
        },
        {
          text: "学习报告",
          pic: "https://resources.sxsaas.com/static/jigou-saas-app/class4.png",
        },
        {
          text: "考试报告",
          pic: "https://resources.sxsaas.com/static/jigou-saas-app/class5.png",
        },
        {
          text: "个人专考",
          pic: "https://resources.sxsaas.com/static/jigou-saas-app/class6.png",
        },
        {
          text: "错题查看",
          pic: "https://resources.sxsaas.com/static/jigou-saas-app/class7.png",
        },
        {
          text: "错题专考",
          pic: "https://resources.sxsaas.com/static/jigou-saas-app/class8.png",
        },
        {
          text: null,
          pic: null,
        },
      ],
      isShowCuotizhuankaoPop:false,
    };
  },
  methods: {
    lookSubject(index) {
      console.log(index);
      switch (parseInt(index)) {
        case 0:
          window.localStorage.setItem("lookIndex", 0)
          this.$router.push({ name: 'LookList' });
          break;
        case 1:
          window.localStorage.setItem("lookIndex", 1)
          this.$router.push({ name: 'LookList' });
          break;
        default:
          break;
      }
    },
    backPage() {
      this.$router.back()
    },
    setDate(data) {
      let tempArr = []
      for (let i = 0; i < data.length; i++) {
        tempArr[i] = data[i].days
      }
      return tempArr
    },
    async jumpPage(index) {
      switch (parseInt(index)) {
        case 0:
          console.log("单科练习");
          this.$router.push({ name: "DKLXList" });
          break;
        case 1:
          console.log("综合模拟");
          this.$router.push({ name: "TYKSList", query: { examType: global.ExamType.MoKao } });
          break;
        case 2:
          console.log("统一考试");
          this.$router.push({ name: "TYKSList", query: { examType: global.ExamType.TongKao } });
          break;
        case 3:
          let params1 = {
            pro_id: window.localStorage.getItem("proID"),
            isShowErr: true,
          }
          // 获取日期列表
          let res1 = await _getDateListStudy(params1)

          let dateArr1 = this.setDate(res1.data[0])//格式化日期 获取数组
          if (dateArr1.length <= 0) {
            return this.$message({ message: "暂无学习记录", type: "error" })
          }

          console.log("学习报告");
          window.localStorage.setItem("bigScreenNavIndex", 0)
          let routeData1 = this.$router.resolve({
            name: "BigScreenStudy",
          });
          window.open(routeData1.href, '_blank');
          // this.$router.back(-1)
          break;
        case 4:
        let params2 = {
            pro_id: window.localStorage.getItem("proID"),
            isShowErr: true,
          }

          // 获取日期列表
          let res2 = await _getDateList(params2)
          let dateArr2 = this.setDate(res2.data[0])//格式化日期 获取数组
          if (dateArr2.length <= 0) {
            return this.$message({ message: "暂无考试记录", type: "error" })
          }

          console.log("考试报告");
          window.localStorage.setItem("bigScreenNavIndex", 1)
          let routeData2 = this.$router.resolve({
            name: "BigScreenExam",
          });
          window.open(routeData2.href, '_blank');
          break;
        case 5:
          console.log("个人专考");
          this.handleZhuanKao();
          break;
        case 6:
          console.log("错题查看");
          this.handleCuoTiChaKan();
          break;
        case 7:
          console.log("错题专考");
          this.isShowCuotizhuankaoPop = true;
          break;

        default:
          break;
      }
    },
    async handleZhuanKao() {
      const res = await examinationApi.getZhuanKaoExamQues({
        pro_id: window.localStorage.getItem("proID"),
        load: true,
        isShowErr: true
      });
      global.clearExamStorage();
      window.sessionStorage.setItem("isCanJumpExam", "true");
      window.localStorage.setItem("exam_ques", JSON.stringify(res.data))
      window.localStorage.setItem("exam_id", res.data.exam_id)
      this.$router.replace({ name: "TYKSPage",query:{
        examType:global.ExamType.ZhuanKao
      } });
    },
    handleCuoTiChaKan() {
      this.$router.replace({ name: "WrongCheck" });
    },
    async handleCuoTiZhuanKao(data) {
      data.form.set_start_time = global.formatTime(data.form.set_start_time);
      data.form.set_end_time = global.formatTime(data.form.set_end_time);

      const res = await wrongCheckApi.getCuoTiZhuanKaoExamQues({
        pro_id: window.localStorage.getItem("proID"),
        ...data.form,
        load: true,
        isShowErr: true
      });

      this.isShowCuotizhuankaoPop = false;

      res.data.ques.forEach(item => {
        item.ques_type = item.question_type;
        item.id = item.ques_id;
        return item;
      });

      global.clearExamStorage();
      window.sessionStorage.setItem("isCanJumpExam", "true");
      window.localStorage.setItem("exam_ques", JSON.stringify(res.data))
      window.localStorage.setItem("exam_id", res.data.errors_id)
      window.sessionStorage.setItem("wrongExam", JSON.stringify({errors_id:res.data.errors_id,ques_num:res.data.ques_num, ...data}));
      this.$router.replace({ name: "TYKSPage",query:{
        examType:global.ExamType.CuoTi
      } });
    }
  },
};
</script>
<style scoped>
.main_box {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.main_box .left {
  width: 796px;
  display: flex;
  box-sizing: border-box;
  padding: 16px;
  padding-bottom: 0;
  background-color: #fff;
  flex-wrap: wrap;
  justify-content: space-between;
}

.main_box .right {
  width: 389px;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 16px;
  background-color: #fff;
}

.exam_item {
  width: 244px;
  height: 136px;
  position: relative;
  margin-bottom: 16px;
  cursor: pointer;
}

.exam_item.invisible {
  visibility: hidden;
}

.exam_item img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.exam_item .text {
  color: rgba(245, 245, 245, 1);
  font-weight: 700;
  font-size: 18px;
  position: absolute;
  left: 20px;
  top: 28px;
  z-index: 2;
}

.main_box .left .exam_item:nth-of-type(4) .text {
  color: rgba(116, 125, 254, 1);
}

.main_box .left .exam_item:nth-of-type(5) .text {
  color: rgba(42, 185, 101, 1);
}

.main_box .left .exam_item:nth-of-type(6) .text {
  color: rgba(255, 172, 48, 1);
}

.main_box .left .exam_item:nth-of-type(7) .text {
  color: #FE7474;
}

.main_box .left .exam_item:nth-of-type(8) .text {
  color: #55ADFF;
}

.main_box .right .title {
  width: 100%;
  height: 30px;
  border-bottom: 1px solid rgba(235, 238, 245, 1);
}

.btn_box {
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  padding: 60px 60px 0 60px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.btn_item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn_item img {
  width: 88px;
  height: 88px;
}

.btn_item .text {
  font-weight: 700;
}

.back_box {
  width: 1200px;
  height: 60px;
  background-color: #fff;
  margin: 20px auto 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 16px;
}

.back_btn {
  width: 95px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(122, 152, 249, 1);
  border-radius: 4px;
  cursor: pointer;
}

.back_btn img {
  width: 20px;
  height: 20px;
  color: rgba(29, 37, 54, 1);
  font-size: 18px;

}

.back_btn .text {
  margin-top: -1px;
  margin-left: 8px;
}

.class_name {
  color: rgba(22, 27, 38, 1);
  font-weight: 700;
  font-size: 16px;
}
</style>
