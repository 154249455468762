import * as wrongCheckApi from '@/api/wrongCheck.js';
import * as utils from '@/utils';
import * as global from './global.js';

class QuestionWrapper {
  index = 0;
  question = null;
  operationSymbols = []; //选项的标识 A B C D
  operation = {
    answer: null,
    is_id: null, //本次答题的选项 单选和判断为数字  多选为1、2、3
    start_time: null, //本次进入该题的时间
    // end_time: null, //本次离开该题的时间
    res_time: null, //本次答该题的时间
    right_or_wrong: null, //本次答该题的对错
    isSigned: false, //是否标记
    isCollected: false, //是否收藏
    isAnswered: false, //是否答过
    isShowAnwser: false, //是否显示答案
    isShowAnalysis: true, //是否显示解析
  };
  clearOperation() {
    this.operation.answer = null;
    this.operation.is_id = null;
    this.operation.start_time = null;
    // this.operation.end_time = null;
    this.operation.res_time = null;
    this.operation.right_or_wrong = null;
    this.operation.isAnswered = false;
    this.operation.isShowAnwser = false;
  }
}

export default {

  data() {
    return {
      m_questions: [],
      m_sub_name: null,

      m_subList: [],

      m_search: {
        start_time: null,
        end_time: null,
        wrong_type: 0,
        sub_id: 0,
        port_type: 0
      },

      m_total: 0,
      m_page: 0,
      m_last_page: 0,

      m_loading: false,
      m_noData: false,
    }
  },

  created() {
    //pro_id 从缓存得到
    //班级id
    this.m_pro_id = localStorage.getItem('proID');
    this.PageQuestionNum = 20;
  },

  methods: {

    m_init(submitCallback) {
      this.m_loadSubList();
      this.m_loadData(1);
    },

    m_loadData(page) {
      const loadingInstance = global.showLoading('加载中');
      wrongCheckApi.viewList({
        pro_id: this.m_pro_id,
        listRows: this.PageQuestionNum,
        page,
        ...this.m_search
      }).then(res => {
        if (page == 1) {
          this.m_questions = [];
          this.$refs.ques_list.scrollTo({
            top: 0
          });
          this.m_noData = res.data.total == 0;
        }
        this._m_handle_init_data(res.data);
      }).finally(() => {
        loadingInstance.close();
        this.m_loading = false;
      })
    },

    m_loadSubList() {
      //获取科目列表
      wrongCheckApi.subList({
        pro_id: this.m_pro_id,
      }).then(res => {
        //科目列表
        this.m_subList = res.data;
      })
    },

    m_wrongAnswers(questionWP) {
      return questionWP.question.err_answer.map(answers => {
        answers = answers.replaceAll('、', ',');
        return answers.split(',').sort().map(answer => {
          const index = questionWP.question.test_ques.findIndex(item => item.is_id == answer);
          return questionWP.operationSymbols[index];
        }).join('');
      }).join(' ');
    },

    m_nextPage() {
      if (this.m_loading || this.m_page >= this.m_last_page)
        return;
      this.m_loading = true;
      this.m_loadData(this.m_page + 1);
    },


    m_canUIShowAnsered(currentQuestion) {
      return true;
    },


    m_collect(currentQuestion) {
      const questionWrapper = currentQuestion;
      const question = questionWrapper.question;
      const operation = questionWrapper.operation;

      wrongCheckApi.collect({
        sub_id:question.sub_id,
        version_id:question.version_id,
        pro_id:this.m_pro_id,
        ver_ques_id:question.ver_ques_id,
        ques_type:question.question_type,
        ques_id:question.ques_id,
      }).then(res => {
        this.$set(operation, 'isCollected', res.data.is_collect);
      })
    },

    m_checkOptionSelected(currentQuestion, option) {
      const questionWrapper = currentQuestion;
      const question = questionWrapper.question;
      const operation = questionWrapper.operation;
      let selected = null;

      const is_ids = operation.is_id ? operation.is_id.toString().split(',').map(item => parseInt(item)) : [];
      if (is_ids.includes(option.is_id)) {
        if (!operation.isAnswered)
          selected = 0;
        else
          selected = option.is_item ? 1 : -1;
      }
      return selected;
    },


    _m_handle_init_data(data) {
      this.m_total = data.total;
      this.m_page = data.current_page;
      this.m_last_page = data.last_page;
      this._m_loadData(data.data);
    },

    _m_loadData(ques) {
      let index = (this.m_page - 1) * this.PageQuestionNum;
      const questions = ques.map(item => {
        const questionWrapper = new QuestionWrapper();
        questionWrapper.question = item;
        questionWrapper.index = index;
        questionWrapper.operation.isCollected = questionWrapper.question.is_collect;

        questionWrapper.operationSymbols = questionWrapper.question.test_ques.map((v, index) => {
          return String.fromCharCode(65 + index);
        });
        if (item.your_answer) {
          questionWrapper.operation.right_or_wrong = item.right_or_wrong;
          questionWrapper.operation.isAnswered = true;
          questionWrapper.operation.is_id = item.your_answer;
          questionWrapper.operation.isShowAnwser = true;
        }

        index++;
        return questionWrapper;
      });

      questions.forEach(item => {
        item.operation.isAnswered = true;
        item.operation.isShowAnwser = true;
        item.operation.is_id = item.question.test_ques.filter(item => item.is_item).map(item => item.is_id).join(
          ',');
        item.operation.right_or_wrong = 1;
      });

      this.m_questions = [...this.m_questions, ...questions];
    }
  }
}
