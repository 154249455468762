<template>
  <div class="Comfirm_shade_box">
    <div class="Comfirm_box">
      <div class="title-box">
        <div class="title">错题专考</div>
        <div class="close" @click="$emit('close')"><i class="el-icon-close"></i></div>
      </div>
      <div class="content_box">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="选择科目">
            <el-select class="picker" v-model="form.sub_id">
              <el-option v-for="item in sub_ids" :label="item.sub_name" :value="item.id + ''"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择错题">
            <el-select class="picker" v-model="form.wrong_type">
              <el-option v-for="(item,index) in wrong_types" :label="item" :value="index + ''"></el-option>

            </el-select>
          </el-form-item>
          <el-form-item label="选择类型">
            <el-select class="picker" v-model="form.port_type">
              <el-option v-for="(item,index) in port_types" :label="item" :value="index + ''"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="选择日期">
            <el-date-picker class="picker" v-model="form.set_start_time" type="date"
              placeholder="选择开始日期"></el-date-picker>
            <span class="line"></span>
            <el-date-picker class="picker" v-model="form.set_end_time" type="date"
              placeholder="选择结束日期"></el-date-picker>
          </el-form-item>

          <el-form-item label="错题次数">
            <el-input class="number" v-model="form.tiny_num" placeholder="最小值" type="number"></el-input>
            <span class="line"></span>
            <el-input class="number" v-model="form.big_num" placeholder="最大值" type="number"></el-input>
          </el-form-item>

          <el-form-item label="题目数量">
            <el-input class="number" v-model="form.ques_num" placeholder="请输入" style="width: 100px;" max="200"
              type="number"></el-input>
          </el-form-item>

          <el-form-item label="计算题数">
            <el-input class="number" :value="jiSuanTiShu" style="width: 100px;" readonly></el-input>
          </el-form-item>

        </el-form>
      </div>
      <div class="btn_box">
        <div class="queding com_btn" @click="onJiSuanTiShu">计算题数</div>
        <div class="queding com_btn" @click="onStart">开始答题</div>
      </div>
    </div>
  </div>
</template>


<script>
  import * as wrongCheckApi from '@/api/wrongCheck.js';
  import * as global from '@/mixins/global.js';

  export default {
    name: "comfirm",
    props: {
      data: Object
    },
    components: {},
    created() {

    },

    mounted() {
      this.initSubIds();
    },

    watch: {
      'form.ques_num'(newValue, oldValue) {
        if (newValue > 200) {
          this.form.ques_num = 200;
        }
      }
    },

    data() {
      return {
        form: {
          sub_id: '0',
          wrong_type: '0',
          port_type: '0',
          set_start_time: undefined,
          set_end_time: undefined,
          tiny_num: undefined,
          big_num: undefined,
          ques_num: 100,
        },
        jiSuanTiShu: null,
        sub_ids: [{
          id: 0,
          sub_name: '全部科目'
        }],
        wrong_types: [
          '全部错题',
          '当前错题',
          '历史错题'
        ],
        port_types: [
          '全部类型',
          '练习',
          '考试'
        ],
      }
    },
    methods: {
      initSubIds() {
        wrongCheckApi.subList({
          pro_id: window.localStorage.getItem("proID"),
        }).then(res => {
          this.sub_ids = [...this.sub_ids, ...res.data];
        })
      },
      onJiSuanTiShu() {
        if (!this.verification())
          return;

        const set_start_time = global.formatTime(this.form.set_start_time);
        const set_end_time = global.formatTime(this.form.set_end_time);


        wrongCheckApi.calculateQuesNum({
          pro_id: window.localStorage.getItem("proID"),
          load: true,
          ...this.form,
          set_start_time,
          set_end_time
        }).then(res => {
          this.jiSuanTiShu = res.data.ques_num;
        })
      },
      onStart() {
        if (!this.verification())
          return;
        this.$emit('start', {
          form: this.form,
          data: {
            sub_ids: this.sub_ids,
            wrong_types: this.wrong_types,
            port_types: this.port_types
          }
        })
      },
      verification() {
        if (this.form.set_start_time && this.form.set_end_time) {
          if (new Date(this.form.set_end_time) < new Date(this.form.set_start_time)) {
            global.showMessage('结束日期不能小于开始日期');
            return false;
          }
        }
        if (this.form.tiny_num && this.form.tiny_num < 1 || this.form.big_num && this.form.big_num < 1) {
          global.showMessage('错误次数至少为1');
          return false;
        }
        if (this.form.tiny_num && this.form.big_num) {
          if (this.form.big_num < this.form.tiny_num) {
            global.showMessage('最大值不能小于最小值');
            return false;
          }
        }
        return true;
      }
    },
  };
</script>

<style scoped>
  .Comfirm_shade_box {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .2);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
  }

  .Comfirm_box {
    width: 663px;
    border-radius: 20px;
    position: fixed;
    left: 50%;
    top: 48%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    overflow: hidden;
    position: relative;
  }

  .Comfirm_box .title-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 33px;
    height: 50px;
    line-height: 50px;
    background: rgba(100, 110, 255, 1);
    color: #fff;
    font-size: 18px;
  }

  .title-box .close {
    cursor: pointer;
  }

  .Comfirm_box .content_box {
    padding: 20px 30px;
  }



  .Comfirm_box .info_text {
    width: 360px;
    text-align: center;
    color: rgba(114, 123, 142, 1);
    font-size: 20px;
    position: absolute;
    left: 50%;
    top: 128px;
    transform: translate(-50%, 0);

  }

  .Comfirm_shade_box .btn_box {
    width: 100%;
    box-sizing: border-box;
    padding: 0 30px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .Comfirm_shade_box .com_btn {
    width: 100px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    border: 1px solid rgba(122, 152, 249, 1);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 700;
    margin: 0 40px;
  }

  .queding {
    color: #fff;
    background: rgba(100, 110, 255, 1);
    border: 1px solid rgba(100, 110, 255, 1);
  }

  .picker {
    width: 180px;
  }

  .picker.el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 180px;
  }

  .number {
    width: 100px;
  }

  .line {
    display: inline-block;
    margin: 0 12px;
    width: 5px;
    height: 1px;
    background-color: #000;
  }
</style>
