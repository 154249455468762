<template>
    <div class="bs_main_box">
        <div v-if="dateArr.length > 0">
            <div class="tool_box">
                <div class="left">
                    <div class="btn_item" :class="[examTypeInde == index ? 'btn_item_select' : '']"
                        v-for="(item, index) in examType" :key="index" @click="changeExamType(index)">
                        {{ item }}
                    </div>

                </div>
                <div class="right">

                    <el-select v-model="selectValue" placeholder="请选择" popper-class="selectstyle_dark" @change="changeDrop"
                        style="margin-right: 0.2rem;">
                        <el-option v-for="item in examList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-date-picker v-model='dateValue' type='date' placeholder='请选择日期' popper-class="datestyle_dark"
                        value-format="yyyy-MM-dd" :picker-options="pickerOptions" @change="changeDate">
                    </el-date-picker>

                </div>
            </div>
            <div class="hold"></div>
            <div v-show="examTypeInde == 0">
                <div class="row_1">
                    <div class="cor_1">
                        <div class="report_btn" @click="wrongReport">
                            <div class="report_btn_img_box">
                                <img class="img_1" src="../../img/bc_pic3.png" alt="">
                                <img class="img_2" src="../../img/bc_pic4.png" alt="">
                                <img class="img_3" src="../../img/bc_pic4.png" alt="">
                            </div>

                            <div class="text">错题导出</div>
                        </div>
                        <div class="report_btn">
                            <div class="report_btn_img_box" @click="subjectReport">
                                <img class="img_1" src="../../img/bc_pic6.png" alt="">
                                <img class="img_2" src="../../img/bc_pic5.png" alt="">
                                <img class="img_3" src="../../img/bc_pic5.png" alt="">
                            </div>
                            <div class="text">试卷导出</div>
                        </div>
                    </div>
                    <div class="cor_2">
                        <myBorder></myBorder>
                        <div class="title">成绩</div>
                        <div class="point_box">
                            <div class="point_box_2"></div>
                            <div class="ld_box">
                                <img src="../../img/ld.png" class="ld" alt="">
                            </div>
                            <div class="point_text" v-if="pageInfo.exam">{{ pageInfo.exam.score }}</div>
                        </div>
                        <div class="text_hint" v-if="pageInfo.exam">
                            <div class="text">本次成绩</div>
                            <img src="../../img/jt_up.png" alt="" v-if="pageInfo.exam.score_step == 1">
                            <img src="../../img/jt_down.png" alt="" v-if="pageInfo.exam.score_step == -1">
                            <!-- <img src="../../img/jt_down.png" alt=""> -->
                        </div>
                    </div>
                    <div class="cor_3">
                        <myBorder></myBorder>
                        <div class="title">考试用时</div>
                        <div class="time_box" v-if="pageInfo.exam">
                            <div class="text1">{{ pageInfo.exam.duration }}</div>
                            <div class="text2">
                                <div>总计用时</div>
                                <img src="../../img/jt_up.png" alt="" v-if="pageInfo.exam.duration_step == 1">
                                <img src="../../img/jt_down.png" alt="" v-if="pageInfo.exam.duration_step == -1">
                            </div>
                        </div>
                        <div class="time_box" v-if="pageInfo.exam">
                            <div class="text1">{{ pageInfo.exam.ques_duration }}</div>
                            <div class="text2">
                                <div>单题用时</div>
                                <img src="../../img/jt_up.png" alt="" v-if="pageInfo.exam.ques_duration_step == 1">
                                <img src="../../img/jt_down.png" alt="" v-if="pageInfo.exam.ques_duration_step == -1">
                            </div>
                        </div>
                    </div>
                    <div class="cor_2">
                        <myBorder></myBorder>
                        <div class="title">排名</div>
                        <div class="point_box">
                            <div class="point_box_2"></div>
                            <div class="ld_box">
                                <img src="../../img/ld.png" class="ld" alt="">
                            </div>
                            <div class="point_text" v-if="pageInfo.exam">{{ pageInfo.exam.rank }}</div>
                        </div>
                        <div class="text_hint" v-if="pageInfo.exam">
                            <div class="text">本次排名</div>
                            <img src="../../img/jt_up.png" alt="" v-if="pageInfo.exam.rank_step == 1">
                            <img src="../../img/jt_down.png" alt="" v-if="pageInfo.exam.rank_step == -1">
                        </div>
                    </div>
                    <div class="cor_5">
                        <myTitleBox title="各题型的正确率（%）"></myTitleBox>
                        <div class="info_box">
                            <myBorder2></myBorder2>
                            <div class="info_box_info">
                                <myBorder></myBorder>
                                <MyChart1 :pageData="pageInfo.ques_type"></MyChart1>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row_2">
                    <myTitleBox title="考试每道题时间曲线"></myTitleBox>
                    <div class="info_box">
                        <myBorder2></myBorder2>
                        <div class="info_box_info" style="box-sizing: border-box;">
                            <myBorder></myBorder>
                            <MyChart2
                                :pageData="{ pageInfo: pageInfo.ques_time, pageColor: ['rgb(255, 125, 0)', 'rgb(255, 235, 139)', 'rgb(36, 158, 255)', 'rgb(245, 49, 157)', 'rgb(60, 223, 150)', 'rgb(115, 128, 255)', 'rgb(148, 217, 255)'] }">
                            </MyChart2>
                        </div>
                    </div>
                </div>
                <div class="row_2" v-if="pageInfo.ques_info">
                    <myTitleBox :title="'答题情况：' + pageInfo.ques_info.exam_title"></myTitleBox>
                    <div class="info_box">
                        <myBorder2></myBorder2>
                        <div class="info_box_info" style="box-sizing: border-box;">
                            <myBorder></myBorder>
                            <MyChart8 :pageData="{ pageInfo: pageInfo.ques_info }"></MyChart8>

                            <!-- <MyChart2
                                :pageData="{ pageInfo: pageInfo.ques_time, pageColor: ['rgb(255, 125, 0)', 'rgb(255, 235, 139)', 'rgb(36, 158, 255)', 'rgb(245, 49, 157)', 'rgb(60, 223, 150)', 'rgb(115, 128, 255)', 'rgb(148, 217, 255)'] }">
                            </MyChart2> -->
                        </div>
                    </div>
                </div>
                <!-- <div class="row_2">
                    <myTitleBox title="考试每道难度曲线"></myTitleBox>
                    <div class="info_box">
                        <myBorder2></myBorder2>
                        <div class="info_box_info2">
                            <myBorder></myBorder>
                            <MyChart2></MyChart2>
                        </div>
                    </div>
                </div> -->
                <div class="row_2">
                    <myTitleBox title="本次考试知识点冰点图"></myTitleBox>
                    <div class="info_box">
                        <myBorder2></myBorder2>
                        <div class="info_box_info2">
                            <myBorder></myBorder>
                            <MyChart3 :pageData="{ pageInfo: pageInfo.know_hot, pageColor: ['#00d8ff', '#8cffe3'] }">>
                            </MyChart3>
                        </div>
                    </div>
                </div>
                <div class="charts_end_box">
                    <endBox></endBox>
                </div>
            </div>

            <div v-show="examTypeInde == 1 && pageInfoAll != null" class="page_all_box">
                <div class="row_1">
                    <div class="cor_2">
                        <myBorder></myBorder>
                        <div class="title">平均成绩</div>
                        <div class="point_box">
                            <div class="point_box_2"></div>
                            <div class="ld_box">
                                <img src="../../img/ld.png" class="ld" alt="">
                            </div>
                            <div class="point_text" v-if="pageInfoAll != null">{{ pageInfoAll.info.ave_score }}</div>
                        </div>
                        <div class="text_hint">
                            <div class="text">本次成绩</div>
                            <!-- <img src="../../img/jt_up.png" alt=""> -->
                            <!-- <img src="../../img/jt_down.png" alt=""> -->
                        </div>
                    </div>
                    <div class="cor_3">
                        <myBorder></myBorder>
                        <div class="title">考试用时</div>
                        <div class="time_box">
                            <div class="text1" v-if="pageInfoAll != null">{{ pageInfoAll.info.sum_duration }}</div>
                            <div class="text2">
                                <div>总计用时</div>
                                <!-- <img src="../../img/jt_up.png" alt=""> -->
                                <!-- <img src="../../img/jt_down.png" alt=""> -->
                            </div>
                        </div>
                        <div class="time_box">
                            <div class="text1" v-if="pageInfoAll != null">{{ pageInfoAll.info.ques_duration }}</div>
                            <div class="text2">
                                <div>单题用时</div>
                                <!-- <img src="../../img/jt_up.png" alt=""> -->
                                <!-- <img src="../../img/jt_down.png" alt=""> -->
                            </div>
                        </div>
                    </div>
                    <div class="cor_2">
                        <myBorder></myBorder>
                        <div class="title">排名</div>
                        <div class="point_box">
                            <div class="point_box_2"></div>
                            <div class="ld_box">
                                <img src="../../img/ld.png" class="ld" alt="">
                            </div>
                            <div class="point_text" v-if="pageInfoAll != null">{{ pageInfoAll.info.rank }}</div>
                        </div>
                        <div class="text_hint">
                            <div class="text">本次排名</div>
                            <!-- <img src="../../img/jt_up.png" alt=""> -->
                            <!-- <img src="../../img/jt_down.png" alt=""> -->
                        </div>
                    </div>
                    <div class="cor_6">
                        <myTitleBox title="分数区间图"></myTitleBox>
                        <div class="info_box">
                            <myBorder2></myBorder2>
                            <div class="info_box_info" style="" v-if="pageInfoAll != null">
                                <myBorder></myBorder>
                                <MyChart7 v-if="pageInfoAll != null"
                                    :pageData="{ seven: pageInfoAll.score_section_seven, all: pageInfoAll.score_section_all, pageColor: ['#29E2FE', '#2AB7FF', '#94D9FF', '#91E49C', '#5194EF', '#6CA5FF'] }">
                                </MyChart7>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="row_1" style="">
                    <div class="row_2 row_2_size1">
                        <myTitleBox title="考试成绩曲线"></myTitleBox>
                        <div class="info_box">
                            <myBorder2></myBorder2>
                            <div class="info_box_info" style="box-sizing: border-box;" v-if="pageInfoAll != null">
                                <myBorder></myBorder>
                                <MyChart5
                                    :pageData="{ pageInfo: pageInfoAll.exam_score, pageColor: ['#41ABFF', '#22FFCD', '#7737FF'], smooth: false, XYName: { x: '日期', y: '分数' } }">
                                </MyChart5>
                            </div>
                        </div>
                    </div>
                    <div class="row_2 row_2_size1">
                        <myTitleBox title="考试时间曲线"></myTitleBox>
                        <div class="info_box">
                            <myBorder2></myBorder2>
                            <div class="info_box_info" style="box-sizing: border-box;" v-if="pageInfoAll != null">
                                <myBorder></myBorder>
                                <MyChart5
                                    :pageData="{ pageInfo: pageInfoAll.exam_time, pageColor: ['#41ABFF', '#22FFCD', '#7737FF'], smooth: true, XYName: { x: '日期', y: '时间' } }">
                                </MyChart5>
                            </div>
                        </div>
                    </div>
                    <div class="row_2 row_2_size1">
                        <myTitleBox title="几天内的排行变化"></myTitleBox>
                        <div class="info_box">
                            <myBorder2></myBorder2>
                            <div class="info_box_info" style="box-sizing: border-box;" v-if="pageInfoAll != null">
                                <myBorder></myBorder>
                                <MyChart5
                                    :pageData="{ pageInfo: pageInfoAll.rank_change, pageColor: ['#0becd6'], smooth: true, XYName: { x: '日期', y: '名次' }, isShowColor: true }">
                                </MyChart5>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="row_2">
                    <myTitleBox title="考试每道题时间曲线"></myTitleBox>
                    <div class="info_box">
                        <myBorder2></myBorder2>
                        <div class="info_box_info" style="box-sizing: border-box;" v-if="pageInfoAll != null">
                            <myBorder></myBorder>
                            <MyChart3
                                :pageData="{ pageInfo: pageInfoAll.know_freezing, pageColor: ['#00d8ff', '#8cffe3'] }">>
                            </MyChart3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="null_hint">当前暂无考试记录</div>
    </div>
</template>
  
<script>
import myBorder from "./components/myBorder.vue"
import myBorder2 from "./components/myBorder2.vue"
import myTitleBox from "./components/myTitleBox.vue"
import MyChart1 from "./components/myCharts1.vue"
import MyChart2 from "./components/myCharts2.vue"
import MyChart3 from "./components/myCharts3.vue"
import MyChart4 from "./components/myCharts4.vue"
import MyChart5 from "./components/myCharts5.vue"
import MyChart6 from "./components/myCharts6.vue"
import MyChart7 from "./components/myCharts7.vue"
import MyChart8 from "./components/myCharts8.vue"
import endBox from "./components/endBox.vue"

import { _getDateList, _getSubjectList, _getSubExamList, _getOneExam, _getAllExam, _reportSubject } from "../../api/bigScreen.js"
import { Loading } from 'element-ui';
export default {
    components: { myBorder, myBorder2, myTitleBox, MyChart1, MyChart2, MyChart3, MyChart4, MyChart5, MyChart6, MyChart7, MyChart8, endBox },

    created() {
        if (parseInt(window.screen.width) >= 1920) {
            this.is1920 = true
        } else {
            this.is1920 = false
        }
        // http://192.168.2.15:8080/#/Index/BigScreen/BigScreenExamJump?exam_id=17943&pro_id=387&stu_id=2&xcust_id=6
        let url = window.location.href
        var temp = url.split('?')[1];
        var params = new URLSearchParams('?' + temp);
        this.reqParams.exam_id = params.get('exam_id')
        this.reqParams.pro_id = params.get('pro_id')
        this.reqParams.stu_id = params.get('stu_id')
        this.reqParams.xcust_id = params.get('xcust_id')

        this.getData()
    },

    destroyed() { },

    mounted() { },
    data() {
        return {
            reqParams: {},
            pageData: [],
            dateArr: [],
            is1920: false,
            // options: [{
            //     value: '选项1',
            //     label: '选项1'
            // }, {
            //     value: '选项2',
            //     label: '选项2'
            // }, {
            //     value: '选项3',
            //     label: '选项3'
            // }, {
            //     value: '选项4',
            //     label: '选项4'
            // }, {
            //     value: '选项5',
            //     label: '选项5'
            // }],
            selectValue: '',
            userName: "",
            dateValue: '',
            isDropShow: false,
            dropIndex: 0,
            dropArr: [],
            dropArrData: [],
            examTypeInde: 0,
            examList: [],
            examListData: [],
            examType: ["单次考试数据", "整体考试数据"],
            pickerOptions: {
                disabledDate: this.disabledDate
            },
            reqExamID: "",
            pageInfo: {},
            pageInfoAll: null,
            loading: null
        };
    },
    beforeDestroy() {
        // 一定要在组件销毁前移除事件监听，避免内存泄露
    },
    methods: {
        async getData() {
            
            this.loading = Loading.service({
                lock: true,
                text: '数据加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)', fullscreen: true
            });
            try {
                let params = {
                    pro_id: this.reqParams.pro_id,
                    stu_id: this.reqParams.stu_id,
                    xcust_id: this.reqParams.xcust_id,
                    isHideToken: true,
                    isShowErr: true
                }
                // 获取日期列表
                let res = await _getDateList(params)
                this.dateArr = this.setDate(res.data[0])//格式化日期 获取数组
                this.userName = res.data[1].stu_name
                this.$emit('send-username', this.userName);

                if (this.dateArr.length <= 0) {
                    this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                        this.loading.close();
                    });
                    return false
                }


                this.dateValue = this.dateArr[this.dateArr.length - 1] //将日期数组的最后一个  设置为日期组件的选中项


                // //获取班级科目列表
                // let res2 = await _getSubjectList(params)
                // this.dropArr =this.setSubjectList(res2.data) //格式化日期 获取数组 用于组件渲染
                // this.dropArrData = res2.data                 //接受数据
                // this.selectValue = this.dropArrData[0].ver.version_name //下拉框文本设置  

                params = {
                    pro_id: this.reqParams.pro_id,
                    stu_id: this.reqParams.stu_id,
                    xcust_id: this.reqParams.xcust_id,
                    isHideToken: true,
                    day: this.dateValue,
                    isShowErr: true
                }

                // 根据日期获取考试列表
                let res3 = await _getSubExamList(params)
                this.examListData = res3.data
                this.examList = this.setExamList(res3.data)
                this.selectValue = this.examList[0].label

                this.reqExamID = this.examListData[0].id
                params = {
                    exam_id: this.reqExamID,
                    stu_id: this.reqParams.stu_id,
                    xcust_id: this.reqParams.xcust_id,
                    isHideToken: true,
                    isShowErr: true
                }
                let res4 = await _getOneExam(params)
                this.pageInfo = res4.data

                params = {
                    pro_id: this.reqParams.pro_id,
                    stu_id: this.reqParams.stu_id,
                    xcust_id: this.reqParams.xcust_id,
                    isHideToken: true,
                    day: this.dateValue,
                    isShowErr: true
                }

                let res5 = await _getAllExam(params)
                this.pageInfoAll = res5.data
                console.log(res5.data);

                this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                    this.loading.close();
                });
            } catch (error) {
                this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                    this.loading.close();
                });
            }

        },
        setDate(data) {
            let tempArr = []
            for (let i = 0; i < data.length; i++) {
                tempArr[i] = data[i].days
            }
            return tempArr
        },
        setSubjectList(data) {
            let tempArr = []
            for (let i = 0; i < data.length; i++) {
                tempArr[i] = {
                    value: i,
                    label: data[i].ver.version_name
                }
            }
            return tempArr
        },
        setExamList(data) {
            let tempArr = []
            for (let i = 0; i < data.length; i++) {
                tempArr[i] = {
                    value: i,
                    label: data[i].title
                }
            }
            return tempArr
        },
        disabledDate(date) {
            if (date.getTime() >= Date.now()) {
                return true
            } else {
                let index = this.dateArr.indexOf(this.timestampToDateTime(date.getTime()))
                console.log(index);
                if (index == -1) {
                    return true
                } else {
                    return false
                }
            }
        },
        timestampToDateTime(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = ('0' + (date.getMonth() + 1)).slice(-2);
            const day = ('0' + date.getDate()).slice(-2);
            const hours = ('0' + date.getHours()).slice(-2);
            const minutes = ('0' + date.getMinutes()).slice(-2);
            const seconds = ('0' + date.getSeconds()).slice(-2);

            return `${year}-${month}-${day}`;
        },
        async changeDrop(val) {
            this.loading = Loading.service({
                lock: true,
                text: '数据加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)', fullscreen: true
            });
            // console.log(val);
            // console.log( this.examListData[val].id);
            try {
                this.reqExamID = this.examListData[val].id
                let params = {
                    exam_id: this.reqExamID,
                    stu_id: this.reqParams.stu_id,
                    xcust_id: this.reqParams.xcust_id,
                    isHideToken: true,
                    isShowErr: true
                }

                let res4 = await _getOneExam(params)
                this.pageInfo = res4.data

                params = {
                    pro_id: this.reqParams.pro_id,
                    stu_id: this.reqParams.stu_id,
                    xcust_id: this.reqParams.xcust_id,
                    isHideToken: true,
                    day: this.dateValue,
                    isShowErr: true
                }

                let res5 = await _getAllExam(params)
                this.pageInfoAll = res5.data
                console.log(res5.data);
                this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                    this.loading.close();
                });
            } catch (error) {
                this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                    this.loading.close();
                });
            }

        },
        async changeDate(val) {
            this.loading = Loading.service({
                lock: true,
                text: '数据加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)', fullscreen: true
            });
            try {
                console.log(val);
                let params = {
                    pro_id: this.reqParams.pro_id,
                    stu_id: this.reqParams.stu_id,
                    xcust_id: this.reqParams.xcust_id,
                    isHideToken: true,
                    day: val,
                    isShowErr: true
                }

                // 根据日期获取考试列表
                let res3 = await _getSubExamList(params)
                this.examListData = res3.data
                this.examList = this.setExamList(res3.data)
                this.selectValue = this.examList[0].label

                this.reqExamID = this.examListData[0].id
                params = {
                    exam_id: this.reqExamID,
                    stu_id: this.reqParams.stu_id,
                    xcust_id: this.reqParams.xcust_id,
                    isHideToken: true,
                    isShowErr: true
                }
                let res4 = await _getOneExam(params)
                this.pageInfo = res4.data

                params = {
                    pro_id: this.reqParams.pro_id,
                    stu_id: this.reqParams.stu_id,
                    xcust_id: this.reqParams.xcust_id,
                    isHideToken: true,
                    day: this.dateValue,
                    isShowErr: true
                }

                let res5 = await _getAllExam(params)
                this.pageInfoAll = res5.data
                console.log(res5.data);
                this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                    this.loading.close();
                });
            } catch (error) {
                this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                    this.loading.close();
                });
            }
        },
        preventRefresh(event) {

        },
        async changeExamType(index) {
            this.examTypeInde = index
            this.$emit('child-clicked', '');

            switch (parseInt(this.examTypeInde)) {
                case 0:
                    this.reqExamID = this.examListData[0].id
                    let params1 = {
                        exam_id: this.reqExamID,
                        stu_id: this.reqParams.stu_id,
                        xcust_id: this.reqParams.xcust_id,
                        isHideToken: true,
                        isShowErr: true
                    }
                    let res4 = await _getOneExam(params1)
                    this.pageInfo = res4.data
                    break;
                case 1:
                    let params2 = {
                        pro_id: this.reqParams.pro_id,
                        stu_id: this.reqParams.stu_id,
                        xcust_id: this.reqParams.xcust_id,
                        isHideToken: true,
                        day: this.dateValue,
                        isShowErr: true
                    }

                    let res5 = await _getAllExam(params2)
                    this.pageInfoAll = res5.data
                    break;
                default:
                    break;
            }
        },
        dropShow() {
            this.isDropShow = this.isDropShow == true ? false : true
        },
        selectDrop(index) {
            this.dropIndex = index
        },
        async wrongReport() {
            this.loading = Loading.service({
                lock: true,
                text: '错题导出中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)', fullscreen: true
            });
            try {
                let params = {
                    exam_id: this.reqExamID,
                    stu_id:this.reqParams.stu_id,
                    xcust_id:this.reqParams.xcust_id,
                    isHideToken:true,
                    check_type: 0,
                    isShowErr: true
                }
                let res = await _reportSubject(params)
                this.downPDF(res.data)
                this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                    this.loading.close();
                });
            } catch (error) {
                this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                    this.loading.close();
                });
            }
        },
        async subjectReport() {
            this.loading = Loading.service({
                lock: true,
                text: '试题导出中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)', fullscreen: true
            });
            try {
                let params = {
                    exam_id: this.reqExamID,
                    stu_id:this.reqParams.stu_id,
                    xcust_id:this.reqParams.xcust_id,
                    isHideToken:true,
                    check_type: 1,
                    isShowErr: true
                }
                let res = await _reportSubject(params)
                this.downPDF(res.data)
                this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                    this.loading.close();
                });
            } catch (error) {
                this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                    this.loading.close();
                });
            }

        },
        downPDF(data) {

            let url = data.download_url
            let name = data.file_name
            // 发送http请求，将文件链接转换成文件流
            let xhr = new XMLHttpRequest()
            xhr.open('get', url, true)
            // 请求类型
            xhr.responseType = 'blob'
            xhr.onreadystatechange = function () {
                // 获取接口结果
                if (xhr.readyState === 4 && xhr.status === 200) {
                    window.URL = window.URL || window.webkitURL
                    let a = document.createElement('a')
                    let blob = new Blob([xhr.response])
                    // 通过二进制文件创建url
                    let url = window.URL.createObjectURL(blob)
                    a.href = url
                    a.download = name
                    a.click()
                    // 销毁创建的url
                    window.URL.revokeObjectURL(url)
                }
            }
            xhr.send()

        },
    },
};
</script>
<style scoped lang="less" type='text/less'>
.bs_main_box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin-top: .75rem;
    padding: 0 .25rem 0 .25rem;
    height: 100%;
    background-color: #071037;
    display: flex;
    flex-direction: column;

}

.tool_box {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: fixed;
    left: 50%;
    top: 1.175rem;
    padding: 0 .25rem .125rem .25rem;
    transform: translate(-50%, 0);
    background-color: #071037;
    z-index: 99;
    box-shadow: 0px 8px 4px -4px rgba(140, 253, 255, 0.06);
    -webkit-box-shadow: 0px 8px 4px -4px rgba(140, 253, 255, 0.06);
    -moz-box-shadow: 0px 8px 4px -4px rgba(140, 253, 255, 0.06);
}

.hold {
    height: 50px;
}

.tool_box .left {
    display: flex;
}

.tool_box .left .btn_item {
    flex-shrink: 0;
    height: .425rem;
    line-height: .425rem;
    padding: 0 .1rem;
    color: #82899e;
    border: 1px solid #0c335a;
    margin-right: .2rem;
    cursor: pointer;
    background-color: #06133b;
}

.btn_item_select {
    color: #1ec1e6 !important;
    border: 1px solid #1ec1e6 !important;
    background-color: #001e4d;
}

.tool_box .right {
    display: flex;
    position: relative;
}


.drop_box::-webkit-scrollbar {
    width: .1rem;
    height: .0625rem;
}

.drop_box::-webkit-scrollbar-thumb {
    background: #1ec1e6;
    border-radius: 0;
}

.drop_box::-webkit-scrollbar-track {
    background-color: #001e4d;
    border: 1px solid #001e4d;
}

.drop_item {
    box-sizing: border-box;
    padding: .1rem .2rem;
    white-space: nowrap;
    font-size: .175rem;
    border-bottom: 1px solid #1ec1e6;
}

.drop_item_select {
    color: #1ec1e6;
}

.drop_item:hover {
    background-color: #1ec1e6;
    color: #fff !important;
}

:deep .el-input__inner {
    height: .475rem;
    display: flex;
    align-items: center;
    border-radius: 0;
    border: 1px solid #1ec1e6;
    background-color: #001e4d;
    color: #fff;
}

:deep .el-input__inner:hover {
    border: 1px solid #1ec1e6 !important;
}

:deep .el-input__inner:focus {
    border: 1px solid #1ec1e6 !important;
}

:deep .el-input__inner:hover {
    border: 1px solid #1ec1e6 !important;
}

:deep .el-input__inner:focus {
    border: 1px solid #1ec1e6 !important;
}

:deep .el-input__icon {
    line-height: .475rem !important;
}

.row_1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: .2rem 0;
}

.row_1>div {
    position: relative;
}

.row_1 .cor_1 {
    width: 5.725rem;
    height: 4.8625rem;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 .25rem
}

.row_1 .cor_1 .report_btn {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.row_1 .cor_1 .report_btn .report_btn_img_box {
    width: 2.4rem;
    height: 2.4rem;
    position: relative;
}

.row_1 .cor_1 .report_btn .report_btn_img_box .img_1 {
    width: 2.4rem;
    height: 2.4rem;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
}

.row_1 .cor_1 .report_btn .report_btn_img_box .img_2 {
    width: 2.4rem;
    height: 2.4rem;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1
}

.row_1 .cor_1 .report_btn .report_btn_img_box .img_3 {
    width: 2.4rem;
    height: 2.4rem;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    animation: rotate2 3s linear infinite;
}

@keyframes rotate2 {
    100% {
        transform-origin: center center;
        transform: rotateY(360deg);
    }
}


.row_1 .cor_1 .report_btn img {
    width: 2.4rem;
    height: 2.4rem;
}

.row_1 .cor_1 .report_btn .text {
    color: rgba(255, 255, 255, 1);
    font-size: .25rem;
    margin-top: .45rem
}

.row_1 .cor_2 {
    width: 2.65rem;
    height: 4.8625rem;
    background: linear-gradient(180deg, rgba(2, 71, 190, 0.19) 0%, rgba(1, 33, 88, 0) 100%);
    box-sizing: border-box;
    padding: .325rem 0;
}

.row_1 .title {
    color: rgba(255, 255, 255, 1);
    font-weight: 700;
    font-size: .25rem;
    padding-left: .325rem;
}

.point_box {
    width: 2.125rem;
    height: 2.125rem;
    box-sizing: border-box;
    border-radius: 50%;
    border: 1px dashed rgba(25, 198, 255, 1);
    ;
    margin: .6875rem auto 0;
    position: relative;
}

.point_box .point_box_2 {
    width: 1.925rem;
    height: 1.925rem;
    box-sizing: border-box;
    border-radius: 50%;
    border: .0375rem solid rgba(25, 198, 255, 1);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.ld_box {
    width: 2.4375rem;
    height: 2.4375rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.point_box .ld_box .ld {
    width: 2.4375rem;
    height: 2.4375rem;
    animation: rotate 5s linear infinite;
}

@keyframes rotate {
    100% {
        transform-origin: center center;
        transform: rotate(360deg);
    }
}

.point_box .point_text {
    color: rgba(140, 253, 255, 1);
    font-weight: 700;
    font-size: .8rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
}

.text_hint {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: .475rem auto 0;
}

.text_hint .text {
    color: rgba(255, 255, 255, 1);
    font-size: .2rem;
}

.text_hint img {
    width: .4rem;
    height: .4rem;
    margin-left: .15rem;
}

.row_1 .cor_3 {
    width: 5.9rem;
    height: 4.8625rem;
    background: linear-gradient(180deg, rgba(2, 71, 190, 0.19) 0%, rgba(1, 33, 88, 0) 100%);
    box-sizing: border-box;
    padding: .325rem 0;
}

.row_1 .cor_3 .text1 {
    color: rgba(140, 253, 255, 1);
    font-weight: 700;
    font-size: .725rem;
    text-align: center;
    margin-top: .375rem;
}

.row_1 .cor_3 .text2 {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    font-size: .2rem;
    margin-top: .175rem;
}

.row_1 .cor_3 .text2 img {
    width: .4rem;
    height: .4rem;
    margin-left: .15rem;
    margin-top: .0375rem;
}



.row_1 .cor_5 {
    width: 5.725rem;
    height: 4.8625rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.row_1 .cor_5 .info_box {
    flex: 1;
    width: 100%;
    background: linear-gradient(180deg, rgba(2, 71, 190, 0.19) 0%, rgba(1, 33, 88, 0) 100%);
    margin-top: .2rem;
    position: relative;
}

.row_1 .cor_6 {
    width: 11.65rem;
    height: 4.8625rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.row_1 .cor_6 .info_box {
    flex: 1;
    width: 100%;
    background: linear-gradient(180deg, rgba(2, 71, 190, 0.19) 0%, rgba(1, 33, 88, 0) 100%);
    margin-top: .2rem;
    position: relative;
}

.info_box_info {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.info_box_info_item {
    width: 50%;
    height: 100%;
    position: relative;
}

.info_box_info2 {
    width: 98%;
    height: 94%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.row_2 {
    width: 100%;
    height: 6.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: .25rem;
}

.row_2 .info_box {
    width: 100%;
    flex: 1;
    margin-top: .2rem;
    position: relative;
}

.charts_end_box {
    width: 100%;
    height: .35rem;
    margin-top: 16px;
}

.row_2 {
    width: 100%;
    height: 6.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: .25rem;
}

.row_2_size1 {
    width: 7.6rem;
    height: 5.875rem;
}

.row_2 .info_box {
    width: 100%;
    flex: 1;
    margin-top: .2rem;
    position: relative;
}

.page_all_box {
    width: 100%;

    flex-shrink: 0;
}

.null_hint {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #409eff;
}
</style>
  