<template>
  <div style="width:100%;height:100%;overflow-y: auto;"  @click="closeBox">
    <div class="main_box">
      <div class="top_box">
        <backBtn></backBtn>
        <div class="right">
          <div class="btn btn_list" @click.stop.prevent="openList1">
            <div>科目：{{ m_subList[m_subjectIndex] && m_subList[m_subjectIndex].sub.sub_name }}</div>
            <img src="../../img/change.png" alt="">
            <div class="right_fix_box" :class="[isListOpen1 == false ? 'right_fix_box_close' : '']">
              <div class="title">科目列表：</div>
              <div class="subject_item" v-for="(item, index) in m_subList" :key="index"
                :class="[index == m_subjectIndex ? 'subject_item_select' : '']"
                @click.stop.prevent="subjectChange(index,item.pro_sub_id)">{{ item.sub.sub_name }}</div>
            </div>
          </div>
          <div class="btn btn_list" @click.stop.prevent="openList2">
            <div>模式：{{ patternType[patternTypeIndex].text }}</div>
            <img src="../../img/change.png" alt="">
            <div class="right_fix_box" :class="[isListOpen2 == false ? 'right_fix_box_close' : '']">
              <div class="title">练习模式：</div>
              <div class="subject_item" v-for="(item, index) in patternType" :key="index"
                :class="[index == patternTypeIndex ? 'subject_item_select' : '']"
                @click.stop.prevent="patternTypeChange(index, item.type)">{{ item.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="padding-bottom: 80px;padding-top: 50px;">
        <div class="row_2">
          <div class="left">
            <div class="qtklx">
              <div class="title_box">
                <div class="title">全题库训练</div>
                <div class="jump_box">
                  <el-input class="sb_number" placeholder="输入题号（非必填）" min="1" v-model="inputInfo" clearable
                    size="small">
                  </el-input>
                  <div class="btn start_btn" @click="m_startQuanTiKu(inputInfo)">开始训练</div>
                </div>
              </div>
              <div class="progress_box">
                <div class="text_box">
                  <div>{{m_process.current}}/{{m_process.total}}</div>
                  <div>答题进度</div>
                </div>
                <el-progress :percentage="m_process.percentage" class="progress" :show-text="false" :stroke-width="10" color="#646eff"
                  define-back-color="#cdd8fd"></el-progress>
              </div>
            </div>
            <div class="select_box">
              <div class="more_box">
                <div class="point_box" @click.stop.prevent="openMoreBox">
                  <div class="point"></div>
                  <div class="point"></div>
                  <div class="point"></div>
                </div>
                <div class="more_list" :class="[isOpenMore == false ? 'more_list_close' : '']">
                  <div class="more_item" @click.stop.prevent="m_showClearCuoTiModal">清除错题</div>
                  <div class="more_item" @click.stop.prevent="m_showClearShouCangModal">清除收藏</div>
                </div>
              </div>
              <div class="wrong_collect_btn" @click="m_startCuoTiZhuanKao">
                <img src="../../img/dklx_wrong.png" alt="">
                <div class="text">我的错题</div>
              </div>
              <div class="wrong_collect_btn" @click="m_startShouCangZhuanKao">
                <img src="../../img/dklx_collect.png" alt="">
                <div class="text">我的收藏</div>
              </div>
              <div class="wrong_collect_btn">
                <img src="../../img/dklx_bjb.png" alt="">
                <div class="text">笔记本</div>
              </div>
              <div class="wrong_collect_btn">
                <img src="../../img/dklx_mncs.png" alt="">
                <div class="text">模拟测试</div>
              </div>
              <div class="wrong_collect_btn">
                <img src="../../img/dklx_wdsj.png" alt="">
                <div class="text">我的试卷</div>
              </div>
            </div>
          </div>
          <div class="middle">
            <div class="zjlx" @click="openTreeShade">
              <img src="../../img/zjlx.png" alt="">
              <div class="text1">章节练习</div>
              <div class="text2">系统性练习知识点</div>
              <div class="text3">马上进入>></div>
            </div>
            <div class="txlx">
              <div class="title">题型练习</div>
              <div class="select_box">
                <div :class="{disable:!m_quesTypeList.find(item=>item.ques_type == m_QuestType.SingleChoise)}" class="subject_type_btn"
                  @click="!m_quesTypeList.find(item=>item.ques_type == m_QuestType.SingleChoise) ? null : m_startTiXingZhuanKao(m_QuestType.SingleChoise)">
                  <img src="../../img/txlx_danxuanti.png" alt="">
                  <div class="text">单选题</div>
                </div>
                <div :class="{disable:!m_quesTypeList.find(item=>item.ques_type == m_QuestType.MultipleChoise)}"
                  class="subject_type_btn"
                  @click="!m_quesTypeList.find(item=>item.ques_type == m_QuestType.MultipleChoise) ? null : m_startTiXingZhuanKao(m_QuestType.MultipleChoise)">
                  <img src="../../img/txlx_duoxuanti.png" alt="">
                  <div class="text">多选题</div>
                </div>
                <div :class="{disable:!m_quesTypeList.find(item=>item.ques_type == m_QuestType.Judge)}" class="subject_type_btn"
                  @click="!m_quesTypeList.find(item=>item.ques_type == m_QuestType.Judge) ? null : m_startTiXingZhuanKao(m_QuestType.Judge)">
                  <img src="../../img/txlx_panduantiti.png" alt="">
                  <div class="text">判断题</div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="title">数据分析</div>
            <div class="data_box">
              <div>
                <div class="data_item">
                  <div>
                    <span class="text1">{{m_analysis.new_mark}}</span>
                    <span class="text2">分钟</span>
                  </div>
                  <div class="text3">今日已学</div>
                </div>
                <div class="data_item">
                  <div>
                    <span class="text1">{{m_analysis.new_rate}}</span>
                    <span class="text2">%</span>
                    <img src="../../img/jt_1.png" alt="">
                  </div>
                  <div class="text3">今日正确率</div>
                </div>
              </div>
              <div>
                <div class="data_item">
                  <div>
                    <span class="text1">{{m_analysis.tire_mark}}</span>
                    <span class="text2">分钟</span>
                  </div>
                  <div class="text3">累计已学</div>
                </div>

                <div class="data_item">
                  <div>
                    <span class="text1">{{m_analysis.top_score}}</span>
                    <span class="text2">分</span>
                  </div>
                  <div class="text3">模拟最高分</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row_3">
          <div class="title">实用工具</div>
          <div class="tool_box">
            <div class="tool_item"></div>
            <div class="tool_item"></div>
          </div>
        </div>
      </div>
    </div>

    <comfirm @clickCancel="cancel" @clickComfirm="comfirm" :data="comfirmData" v-if="isShadeShow"></comfirm>
    <comfirm @clickCancel="cancel2" @clickComfirm="comfirm2" :data="comfirmData2" v-if="isShadeShow2"></comfirm>
    <div class="shadeBox" v-if="isShadeShow3" @click.stop.prevent="closeTreeShade">
      <div class="zhangjie_box">
        <div class="title">
          <div>章节列表</div>
          <img src="../../img/close.png" alt="" @click="closeTreeShade">
        </div>
        <div class="tree_box">
          <el-tree :data="m_unitList" accordion :props="unitListDefaultProps" @node-click="handleNodeClick">
            <span class="tree_other_box" slot-scope="{ node, data }">
              <span>{{ data.unit_name }}</span>
              <div class="info_box">
                <div class="status_btn">{{data.is_then ? '正在进行' : ''}}</div>
                <div class="count_box">{{data.count_sum}}/{{data.ques_num}}</div>
                <div class="start_btn btn" @click.stop.prevent="m_startZhangJieLianXi(data)">开始练习
                  <img src="../../img/jt_3.png" alt="">
                </div>
                <!-- <el-button type="text" size="mini" @click.stop.prevent="() => append(data)">
                                    Append
                                </el-button>
                                <el-button type="text" size="mini" @click="() => remove(node, data)">
                                    Delete
                                </el-button> -->
              </div>
            </span>
          </el-tree>

        </div>
      </div>
    </div>
    <div class="page_end_fixed">
      <endBox></endBox>
    </div>

    <comfirm @clickCancel="clearCancel" @clickComfirm="clearComfirm" :data="clearConfirmModalData"
      v-if="isShowClearConfirmModal"></comfirm>
    <comfirm2 :top="130"  @clickComfirm="cantExerciseComfirm" :data="ce_cantExerciseComfirmData" v-if="ce_isShowCantExerciseModal"></comfirm2>
  </div>
</template>

<script>
  import backBtn from "../../components/backBtn.vue"
  import comfirm from "../../components/comfirm.vue"
  import comfirm2 from "../../components/comfirm2.vue"
  import * as exerciseApi from '@/api/exercise.js';
  import exerciseHomeMixin from '@/mixins/exercise-home.js';
  import cantExerciseMixin from '@/mixins/cant-exercise.js';

  import {
    Loading,
    Message
  } from 'element-ui';

  export default {
    mixins: [exerciseHomeMixin, cantExerciseMixin],
    components: {
      backBtn,
      comfirm,
      comfirm2
    },
    created() {
      this.m_init();
    },

    mounted() {},
    data() {
      return {

        unitListDefaultProps: {
          children: 'son_arr',
          label: 'unit_name'
        },
        isShadeShow: false,
        comfirmData: {
          title: "确认清除收藏",
          img: "https://resources.sxsaas.com/static/jigou-saas-app/comfrim_1.png",
          info: "确认要清除收藏吗？"
        },

        isShadeShow2: false,
        comfirmData2: {
          title: "确认清除错题",
          img: "https://resources.sxsaas.com/static/jigou-saas-app/comfrim_1.png",
          info: "确认要清除错题吗？"
        },

        isShadeShow3: false,
        isListOpen1: false,
        shadeBoxinputInfo: "",
        inputInfo:"",


        isListOpen2: false,
        patternTypeIndex: 0,
        patternType: [
          {
            type:2,
            text:'练题模式'
          },
          {
            type:3,
            text:'测题模式'
          }
        ],



        isOpenMore: false,


        isShowClearConfirmModal: false,
        clearConfirmModalData: {
          // title: "确认清空答题记录",
          img: "https://resources.sxsaas.com/static/jigou-saas-app/comfrim_1.png",
          // info: "确认清空答题记录吗？",
          // data: {},
        },
      };
    },
    methods: {
      openMoreBox() {
        this.isOpenMore = this.isOpenMore == true ? false : true
      },
      selectMore(index) {
        this.isOpenMore = false
        switch (parseInt(index)) {
          case 1:
            this.isShadeShow2 = true
            break;
          case 2:
            this.isShadeShow = true
            break;
          default:
            break;
        }
      },
      openList1() {
        this.isListOpen2 = false
        this.isListOpen1 = this.isListOpen1 == true ? false : true

      },
      subjectChange(index, pro_sub_id) {
        this.subjectIndex = parseInt(index)
        this.isListOpen1 = false
        this.m_subjectChange(pro_sub_id);
      },
      closeBox(){
        console.log(123);
        this.isListOpen1 = false;
        this.isListOpen2 = false;
        this.isOpenMore = false
      },

      openList2() {
        this.isListOpen1 = false
        this.isListOpen2 = this.isListOpen2 == true ? false : true

      },
      patternTypeChange(index,type) {
        this.patternTypeIndex = parseInt(index)
        this.m_pattern_type = type;
        this.isListOpen2 = false
      },
      cancel(value) {
        console.log(value);
        this.isShadeShow = false
      },
      comfirm(value) {
        console.log(value);
        this.isShadeShow = false
      },
      cancel2(value) {
        console.log(value);
        this.isShadeShow2 = false
      },
      comfirm2(value) {
        console.log(value);
        this.isShadeShow2 = false
      },
      handleNodeClick(data) {
        console.log(data);
      },
      openTreeShade() {
        // 获取章节
        this.m_requestUnits();
      },
      closeTreeShade() {
        this.isShadeShow3 = false;
      },
      append(data) {
        console.log(data);
      },
      clearComfirm() {
        this.clearConfirmModalData.confirm().then(res=>{

        }).finally(()=>{
          this.clearCancel();
        });
      },
      clearCancel() {
        this.isShowClearConfirmModal = false;
        this.clearConfirmModalData = {
          img: this.clearConfirmModalData.img
        };
      },
      cantExerciseComfirm() {
        this.$router.back()
      },
    },
  };
</script>
<style scoped>
  .main_box {
    width: 1200px;
    height: 100vh;
    box-sizing: border-box;
    padding-top: 20px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 120px;

  }

  .top_box {
    width: 1200px;
    height: 60px;
    position: fixed;
    top: 70px;
    z-index: 9;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    box-sizing: border-box;
    padding: 0 16px;


  }

  .top_box .right {
    display: flex;
  }

  .top_box .right .btn_list {
    position: relative;
  }

  .right_fix_box {
    width: 345px;
    height: 400px;
    background-color: #fff;
    position: absolute;
    z-index: 9;
    right: 0;
    top: 45px;
    box-shadow: -3px 0px 11px 1px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: -3px 0px 11px 1px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: -3px 0px 11px 1px rgba(0, 0, 0, 0.06);
    transition: all 0.2s ease;
    overflow-y: auto;
    box-sizing: border-box;
    color: #000;

  }

  .right_fix_box .title {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    box-sizing: border-box;
    padding: 10px 16px;
  }

  .right_fix_box_close {
    height: 0px !important;
  }

  .btn {
    padding: 8px 14px;
    background: rgba(100, 110, 255, 1);
    color: #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-left: 15px;
    cursor: pointer;
  }

  .btn img {
    width: 16px;
    height: 16px;
    margin-top: 3px;
    margin-left: 5px;
  }

  .subject_item {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    padding: 0 16px;
    display: flex;
    align-items: center;

    border-top: 1px solid rgb(243, 243, 243);
    cursor: pointer;
  }

  .subject_item_select {
    background: rgba(100, 110, 255, 1);
    color: #fff;
  }

  .subject_item:hover {
    background: rgba(155, 178, 250, 1);
    color: #fff;
  }

  .row_2 {
    width: 100%;
    height: 379px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  .row_2 .left {
    width: 592px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .row_2 .left .qtklx {
    width: 100%;
    height: 158px;
    box-sizing: border-box;
    padding: 18px 16px 20px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
  }

  .row_2 .left .select_box {
    width: 100%;
    flex: 1;
    background-color: #fff;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 42px 0 42px;
    position: relative;
  }

  .select_box .more_box {
    width: 16px;
    height: 24px;
    position: absolute;
    right: 16px;
    top: 16px;

  }

  .more_box .point_box {
    width: 16px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .select_box .more_box .more_list {
    width: 117px;
    height: 80px;
    position: absolute;
    right: 0;
    top: 24px;
    background: rgba(155, 178, 250, 1);
    box-sizing: border-box;
    transition: all 0.1s linear;
    overflow: hidden;
  }

  .more_list_close {
    height: 0 !important;
  }

  .more_item {
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }

  .more_item:hover {
    background: rgba(100, 110, 255, 1);
  }

  .select_box .more_box .point {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: rgba(51, 51, 51, 1);
  }

  .select_box .wrong_collect_btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }

  .wrong_collect_btn img {
    width: 64px;
    height: 64px;
  }

  .wrong_collect_btn .text {
    font-size: 14px;
    margin-top: 8px;
  }

  .row_2 .right {
    flex: 1;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px;
  }

  .row_2 .left .title_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .row_2 .left .title_box .title {
    color: rgba(0, 0, 0, 1);
    font-size: 18px;
    font-weight: 700;
  }

  .row_2 .left .title_box .jump_box {
    display: flex;
  }

  .sb_number {
    width: 180px;
  }

  .jump_box .start_btn {
    padding: 4px 10px !important;
    font-size: 14px;
  }

  .progress_box {
    width: 100%;
    margin-top: 15px;
    font-size: 14px;

  }

  .progress_box .text_box {
    display: flex;
    color: rgba(100, 110, 255, 1);
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .row_2 .middle {
    width: 288px;
    height: 100%;
    margin: 0 16px;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: space-between;
  }

  .row_2 .middle .zjlx {
    width: 288px;
    height: 158px;
    position: relative;
    cursor: pointer;
  }

  .zjlx img {
    width: 100%;
    height: 100%;
  }

  .zjlx .text1 {
    position: absolute;
    left: 13px;
    top: 14px;
    font-weight: 700;
    font-size: 18px;
  }

  .zjlx .text2 {
    color: rgba(0, 0, 0, 1);
    font-size: 12px;
    position: absolute;
    left: 13px;
    top: 55px;
  }

  .zjlx .text3 {
    color: rgb(255, 255, 255);
    font-size: 16px;
    position: absolute;
    left: 13px;
    top: 125px;
    font-weight: 700;
  }

  .row_2 .middle .txlx {
    width: 288px;
    flex: 1;
    background-color: #fff;
    margin-top: 16px;
    box-sizing: border-box;
    padding: 16px;
  }

  .txlx .title {
    color: rgba(29, 37, 54, 1);
    font-weight: 700;
    font-size: 18px;

  }

  .txlx .select_box {
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }

  .subject_type_btn {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subject_type_btn.disable {
    opacity: 0.3;
  }

  .subject_type_btn img {
    width: 50px;
    height: 50px;
  }

  .row_2 .right {
    width: 288px;
    height: 100%;
    background: rgba(238, 242, 254, 1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 13px 0px;
    flex-shrink: 0;

  }

  .row_2 .right .title {
    font-size: 18px;
    font-weight: 700;
    width: 100%;
    text-align: left;
    box-sizing: border-box;
    padding: 0 16px;
  }

  .row_2 .right .data_box {
    width: 100%;
    flex: 1;
    box-sizing: border-box;
    padding: 68px 20px;
    display: flex;
    justify-content: space-between;

  }

  .row_2 .right .data_box>div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .data_box .data_item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .data_box .data_item>div {
    position: relative;
  }

  .data_box .data_item img {
    width: 12px;
    height: 12px;
    position: absolute;
    right: -16px;
    top: 59%;
    transform: translate(0, -50%);
  }

  .data_box .data_item .text1 {
    color: rgba(29, 37, 54, 1);
    font-weight: 700;
    font-size: 20px;
  }

  .data_box .data_item .text2 {

    color: rgba(114, 123, 142, 1);
    font-size: 12px;
  }

  .data_box .data_item .text3 {
    color: rgba(163, 170, 186, 1);
    font-size: 12px;
    margin-top: 17px;
  }

  .row_3 {
    width: 100%;
    height: 321px;
    background-color: #fff;
    margin-top: 16px;
    box-sizing: border-box;
    padding: 12px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .row_3 .title {
    width: 100%;
    font-weight: 700;
    font-style: 18px;
  }

  .row_3 .tool_box {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: 16px;
  }

  .tool_box .tool_item {
    flex: 1;
    height: 100%;
    border-radius: 8px;
  }

  .tool_box .tool_item:nth-of-type(1) {
    background: rgba(236, 245, 255, 1);
    margin-right: 8px;
  }

  .tool_box .tool_item:nth-of-type(2) {
    background: rgba(255, 237, 211, 1);
    margin-left: 8px;
  }

  .shadeBox {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .2);
    z-index: 10;
    box-sizing: border-box;
    padding-top: 60px;
  }

  .zhangjie_box {
    width: 70%;
    height: 80%;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 52%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 14px;
    overflow: hidden;
  }

  .zhangjie_box .title {
    width: 100%;
    height: 45px;
    background-color: rgba(100, 110, 255, 1);
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
  }

  .zhangjie_box .tree_box {
    width: 100%;
    flex: 1;
    box-sizing: border-box;
    padding: 16px 20px;
    overflow-y: auto;
  }

  .zhangjie_box img {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  :deep(.el-tree-node__expand-icon) {
    color: #695fee;
    font-size: 20px;
  }

  :deep(.el-tree-node__content) {
    color: rgba(22, 27, 38, 1);
    font-weight: 700;
    font-size: 16px;
    padding: 4px 0;
  }

  :deep(.el-tree>.el-tree-node) {
    border-bottom: 1px solid rgba(205, 216, 253, 1);
    padding: 10px 0;
  }

  .tree_other_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .tree_other_box .info_box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .tree_other_box .info_box .status_btn {
    color: rgba(41, 203, 149, 1);
    font-size: 14px;
    font-weight: 500;
    margin-right: 20px;
  }

  .tree_other_box .info_box .count_box {
    color: rgba(114, 123, 142, 1);
    font-size: 14px;
    font-weight: 500;
  }

  .tree_other_box .info_box .start_btn {
    font-weight: 500;
    padding: 5px 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  .start_btn img {
    width: 16px;
    height: 16px;
  }
</style>
