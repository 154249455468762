<template>
    <div class="app_page" style="height: 100%;">
        <div class="top_box">
            <div class="user_name">{{ userName }}</div>
            <img class="top_bg" src="../../img/bc_top.png" alt="">
            <div class="title">拾星学习分析展示系统</div>
            <div class="top_btn_box" v-if="isShowNav">
                <div class="top_btn" v-for="(item, index) in navArr" :key="index" @click="changeNav(index)">
                    <img class="btn_bg" src="../../img/bc_btn.png" alt="">
                    <div class="text" :class="[navIndex == index ? 'text_gury' : '']">{{ item }}</div>
                </div>
            </div>
            <img class="reload" src="../../img/bc_reload.png" alt="" @click="reload">
        </div>
        <div class="main_box" ref="mainBox">
            <router-view @child-clicked="handleChildClick" @send-username="getName" />
        </div>
    </div>
</template>
  
<script>
export default {
    components: {},

    created() {
        debugger
        const routeMatches = this.$route.matched;
        console.log(routeMatches);
        console.log(routeMatches[routeMatches.length - 1].name || routeMatches[routeMatches.length - 1].path);
        if (routeMatches[routeMatches.length - 1].name == "BigScreenExamJump") {
            this.isShowNav = false
        } else {
            this.isShowNav = true
            if (window.localStorage.getItem("bigScreenNavIndex")) {
                this.navIndex = parseInt(window.localStorage.getItem("bigScreenNavIndex"))
            } else {
                this.navIndex = 0
                window.localStorage.setItem("bigScreenNavIndex", 0)
            }
        }
        // 

        // this.changeNav(this.navIndex)
    },
    beforeDestroy() {
        window.localStorage.removeItem("bigScreenNavIndex")
    },
    destroyed() {

    },

    mounted() {

    },
    data() {
        return {
            navIndex: null,
            navArr: ["学习", "考试"],
            isFirst: true,
            userName: "",
            isShowNav: true
        };
    },
    methods: {
        getName(data) {
            this.userName = "用户名：" + data
        },
        handleChildClick(data) {
            // console.log('子组件被点击了');
            this.$refs.mainBox.scrollTop = 0;
        },
        reload() {
            this.$router.go(0);
        },
        changeNav(index) {
            this.navIndex = index
            window.localStorage.setItem("bigScreenNavIndex", parseInt(this.navIndex))
            switch (parseInt(index)) {
                case 0:
                    this.$router.replace({ name: "BigScreenStudy" });
                    break;
                case 1:
                    this.$router.replace({ name: "BigScreenExam" });
                    break;
                default:
                    break;
            }

        }
    },
};
</script>
<style scoped>
.app_page {
    width: 100%;
    height: 100%;
    background-color: #071037;
    display: flex;
    flex-direction: column;
}

.app_page_1366 {
    transform: scale();
}

.app_page_1920 {}

.top_box {
    width: 100%;
    height: 1.175rem;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #071037;
    z-index: 55;
}

.top_box .top_bg {
    width: 23.125rem;
    height: 1.175rem;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
}

.top_box .title {
    color: #fff;
    font-size: .45rem;
    font-weight: 700;
    position: absolute;
    left: 50%;
    top: .15rem;
    transform: translate(-50%, 0);
    text-shadow:
        0 0 .0625rem #41dadb,
        /* 前一层：白色主发光 */
        0 0 .125rem #fff,
        /* 次一层：白色次发光 */
        0 0 .25rem #41dadb,
        /* 再添加一层紫色发光作为装饰 */
        0 0 .375rem #49a0d6;
    /* 最外层：紫色背景发光 */
}

.top_btn_box {
    position: absolute;
    left: .25rem;
    bottom: .1875rem;
    display: flex;
}

.top_btn {
    width: 2.5375rem;
    height: .5rem;
    position: relative;
    cursor: pointer;
}

.top_btn .btn_bg {
    width: 100%;
    height: 100%;
}

.top_btn .text {
    color: #82899e;
    font-weight: 700;
    font-size: .25rem;
    position: absolute;
    left: 50%;
    top: 39%;
    transform: translate(-50%, -50%);
}

.text_gury {
    color: rgba(0, 216, 255, 1) !important;
}

.reload {
    width: .3rem;
    height: .3rem;
    position: absolute;
    right: .2975rem;
    top: .64rem;
    cursor: pointer;
}

.main_box {
    box-sizing: border-box;
    padding-top: 1.175rem;
    width: 100%;
    height: 100vh;
    flex-shrink: 0;
    position: fixed;
    left: 0;
    top: 0;
    overflow: auto;
}

.main_box::-webkit-scrollbar {
    width: .2rem;
    height: .25rem;
}

.main_box::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px #8cfdff;
    background-color: #156efe;
}

.main_box::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px #8cfdff;
    border-radius: 5px;
    background-color: #061646;
}

.user_name {
    text-align: right;
    position: fixed;
    right: .8rem;
    top: .66rem;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    z-index: 9999;
}
</style>
  
